import '@brightspace-ui/core/components/alert/alert-toast.js';
import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/button/button-icon.js';
import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/inputs/input-text.js';
import '@brightspace-ui/core/components/inputs/input-search.js';
import '@brightspace-ui-labs/autocomplete/autocomplete.js';

import '../skill-popper/skill-popper.js';
import '../../../../shared/components/general/nova-card/nova-card.js';
import '../../../../shared/components/general/nova-lottie-animation/nova-lottie-animation.js';
import '../../../../shared/components/general/nova-tooltip/nova-tooltip.js';
import { bodySmallStyles, heading1Styles, heading2Styles, labelStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { css, html, LitElement, nothing } from 'lit';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';
import { mapify } from '../../../../../shared/methods.js';
import { NovaAmplitudeMixin } from '../../../../shared/mixins/nova-amplitude-mixin/nova-amplitude-mixin.js';

export default class GetStarted extends NovaAmplitudeMixin(LocalizeNova(RequesterMixin(LitElement))) {

  static get properties() {
    return {
      jobTitleId: { type: String },
      jobTitleName: { type: String },
      jobTitleSOC: { type: String },
      jobTitleSOCId: { type: String },
      _editingTitle: { type: Boolean },
      _hasTitle: { state: true },
      _relevantSkills: { type: Array },
      _editTitleClickedCount: { type: Number },
    };
  }

  static get styles() {
    return [
      bodySmallStyles,
      heading1Styles,
      heading2Styles,
      labelStyles,
      css`
      :host {
        display: block;
      }

      /* page layout + animation sizing */

      .view-layout {
        display: grid;
        grid-template-areas:
          "  animation  "
          "   header    "
          "    soc      ";
        grid-template-columns: auto;
        grid-template-rows: auto;
        margin-bottom: 2rem;
      }

      .headers {
        grid-area: header;
        margin-top: 0.6rem;
      }

      d2l-alert {
        margin-bottom: 0.6rem;
      }

      .subcontent {
        grid-area: soc;
      }

      .animation-content {
        grid-area: animation;
      }

      .lottie-animation {
        margin: auto;
        width: 130px;
      }

      /* main content styling */

      .primary-header-substring {
        color: var(--d2l-color-celestine);
      }

      .hello-header {
        font-weight: 700;
        margin: 0;
      }

      .subtle-subheader {
        color: var(--d2l-color-galena);
        font-weight: normal;
        margin-top: 1rem;
      }

      .d2l-heading-2 {
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 1.2rem;
        margin: 0.6rem 0 0 0;
      }

      .job-title-header {
        display: flex;
        flex-wrap: wrap;
        gap: 0 0.6rem;
        margin: 1rem 0;
      }

      .job-title-header > span {
        flex: 0 1 auto;
        max-width: fit-content;
      }

      .job-title-header > span.title-input-container {
        flex-grow: 1;
        margin-top: 0.6rem;
        max-width: 24rem;
        min-width: 14rem;
      }

      d2l-input-text > d2l-icon {
        padding-right: 0.6rem;
      }

      .highlighted {
        background-color: var(--d2l-color-celestine-plus-2);
        font-weight: 700;
        padding: 0 0.3rem;
      }

      nova-card.soc-match-card {
        width: 100%;
      }

      .soc-match-card > div {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 0.6rem 0.9rem;
      }

      .d2l-heading-4 {
        margin: 0;
      }

      .galena {
        color: var(--d2l-color-galena);
      }

      .soc-container {
        display: flex;
        flex-direction: column;
        row-gap: 0.6rem;
      }

      .soc-industry-label {
        margin: 0;
      }

      /*  Grouping together and setting parent+child specifically as labs component doesn't allow
        proper styling allowance for slotted <d2l-input-search> */
      #auto-complete,
      #soc-search {
        width: clamp(6rem, 85vw, 28rem);
      }

      .skill-popper-label {
        margin-bottom: 0;
      }

      .onboarding-screen-1-subheader {
        font-weight: normal;
      }

      /* temporary repsonsiveness, there is an actual responsive/mobile story in JIRA */
      @media (min-width: 616px) {
        .view-layout {
          grid-template-areas:
            "  header    animation "
            "  header    animation "
            "   soc         soc    ";
          grid-template-columns: 1.5fr 1fr;
        }

        .hello-header {
          font-size: 3.2rem;
          line-height: 3.6rem;
          margin: 2rem 0 0 0;
        }

        .d2l-heading-2 {
          font-size: 1.3rem;
          font-weight: 700;
          line-height: 1.5rem;
          margin: 1.5rem 0 1rem 0;
        }

        .onboarding-screen-1-subheader {
          font-weight: normal;
        }

        .job-title-header {
          gap: 0.9rem 0.6rem;
        }

        .job-title-header > span.title-input-container {
          margin-top: unset;
        }

        .soc-container {
          margin-top: unset;
        }

        nova-card.soc-match-card {
          max-width: 26rem;
        }

        .submit-button {
          margin-top: 2rem;
          width: fit-content;
        }

        .animation-container {
          margin-top: 2.5rem;
        }

        .lottie-animation {
          transform: translateX(2rem); /* spec calls for diff pos than svg natively provides */
          width: 15rem;
        }
      }

      @media (min-width: 1229px) {
        .view-layout {
          grid-template-areas:
            "  header    animation "
            "   soc      animation ";
          grid-template-rows: 1fr 2fr;
        }

        .headers {
          margin-top: 2rem;
        }

        .lottie-animation {
          width: 28rem;
        }
      }
`,
    ];
  }

  constructor() {
    super();
    this._hasTitle = true;
    this._relevantSkills = [];
    this.suggestions = {};
    this._editTitleClickedCount = 0;
  }

  async connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');

    const firstLoginCheck = await this.client.checkFirstTimeUser(this.session.user.guid);

    this._isExistingUser = !firstLoginCheck.isFirstLogin;

    // normalize if we have a title coming in
    if ((!this.jobTitleSOC || !this.jobTitleSOCId || !this.jobTitleId || !this.jobTitleName) && !!this.session.user.title) {
      const normalized = await this.client.similarTitles(this.session.user.title);

      if (normalized.data.title.id === 'ET0000000000000000') {
        this._editingTitle = true;
        return;
      }

      this.jobTitleName = normalized.data.title.name;
      this.jobTitleId = normalized.data.title.id;
      this.jobTitleSOC = normalized.data.title.mapping.socs.length > 0 ? normalized.data.title.mapping.socs[0].name : '';
      this.jobTitleSOCId = normalized.data.title.mapping.socs.length > 0 ? normalized.data.title.mapping.socs[0].id : '';
      this._hasTitle = true;
    } else if (!this.session?.user?.title) {
      this._hasTitle = false;
    }

    this._relevantSkills = (await this.client.skillsByTitleId(this.jobTitleId)).skills.slice(0, 8);
    this._editingTitle = !this.jobTitleName;
  }

  async setTitle() {
    const search = this.shadowRoot.getElementById('soc-search');
    const searchTitle = search.value.split('(')[0].trim();
    const jobId = this.suggestions[searchTitle].id;
    this._relevantSkills = (await this.client.skillsByTitleId(jobId)).skills.slice(0, 8);
    this.jobTitleName = searchTitle;
    this.jobTitleId = jobId;
    this.jobTitleSOC = this.suggestions[searchTitle].mapping.length > 0 ? this.suggestions[searchTitle].mapping[0].name : '';
    this.jobTitleSOCId = this.suggestions[searchTitle].mapping.length > 0 ? this.suggestions[searchTitle].mapping[0].id : '';
    this._editingTitle = false;
    this.requestUpdate();
  }

  async _setUserTitle() {
    const titleElement = this.shadowRoot.getElementById('job-title-input');
    const submittedTitle = titleElement.value;
    this.session.user.title = submittedTitle;

    this._editingTitle = true;
    this._hasTitle = true;
  }

  async _searchChange(e) {
    const autoComplete = this.shadowRoot.getElementById('auto-complete');
    const unfilteredSuggestions = e.detail.value ? await this.client.searchLightcast(e.detail.value, 'title') : [];
    this.suggestions = unfilteredSuggestions.filter(element => {
      return element.mapping.length > 0;
    }).splice(0, 5);

    const suggestionNames = this.suggestions.map(s => ({ value: `${s.name}${s.mapping.length > 0 ? ` (${s?.mapping[0].name})` : '' }` }));
    this.suggestions = mapify(this.suggestions, 'name');
    autoComplete.setSuggestions(suggestionNames);
  }

  _stopInputPropagation(e) {
    const [HOME, END] = [36, 35];
    const { keyCode } = e;
    if (keyCode === HOME || keyCode === END) {
      e.stopPropagation();
    }
  }

  _stopDropdowmPropagation(e) {
    e.stopPropagation();
  }

  _handleEditTitleClicked() {
    this._editTitleClickedCount += 1;
    this._editingTitle = true;
    this.logAmplitudeEvent('industryMatchModified', { userSessionClicks: this._editTitleClickedCount });
  }

  _handleSubmission() {
    const submissionEvent = new CustomEvent('get-started-submitted', {
      detail: { userSelectedJobTitleId: this.jobTitleId, userSelectedJobTitleName: this.jobTitleName, userSelectedJobTitleSOC: this.jobTitleSOC, userSelectedJobTitleSOCId: this.jobTitleSOCId },
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(submissionEvent);
  }

  _truncateString(string = '', maxLength = 30) {
    return string.length > maxLength
      ? `${string.substring(0, maxLength)}…`
      : string;
  }

  /**
   * Place salient styling on first portion of marquee header text
   *
   * Note: This current method is potentially not compatible with languages that are SVO as it may
   * highlight the incorrect side of the sentence in those sentence structures.
   * Ex: Japanese, Korean
   *
   * @param {string} text
   * @returns {TemplateResult}
   */
  _styleHeaderText(text) {
    const commaIndex = text.indexOf(',');
    if (commaIndex !== -1) {
      return html`
        <span class="primary-header-substring">${text.slice(0, commaIndex + 1)}</span>
        <span class="secondary-header-substring">${text.slice(commaIndex + 1)}</span>
      `;
    } else {
      return html`<span class="primary-header-substring">${text.trim()}</span>`;
    }
  }

  render() {
    return html`
      <div class="view-layout">
        <div class="headers">
          ${this._alertTemplate}
          ${this._headerTemplate}
        </div>
        <div class="subcontent">
          ${this._jobTitleTemplate}
          ${this._socTemplate}
        </div>
        <div class="animation-content">${this._animationTemplate}</div>
      </div>
    `;
  }

  get _alertTemplate() {
    if (this._isExistingUser && !this.session.tenant.hasTag('skillsDemo')) {
      return html`
        <d2l-alert type="default">
          ${this.localize('view-onboarding.getStarted.existingUserAlert')}
        </d2l-alert>
      `;
    }
    return nothing;
  }

  get _animationTemplate() {
    return html`

    <div role="img" id="animation-container" aria-label="${this.localize('view-onboarding.getStarted.animationAltText')}">
      <nova-lottie-animation
        role="img"
        aria-labelledby="animation-container"
        class="lottie-animation"
        animation-url="/assets/animation/onboarding-staircase-animation.json"
        auto-play
        loop>
        <img alt=${this.localize('view-onboarding.getStarted.animationAltText')} slot="reduced-motion" height="100%" width="100%" src="/assets/img/onboarding-stair-lady.svg"/>
      </nova-lottie-animation>
    </div>
    `;
  }

  get _headerTemplate() {
    const styledText = this._styleHeaderText(this.localize('view-onboarding.getStarted.marquee.header'));

    return html`
      <h1 class="d2l-heading-1 hello-header">
        ${styledText}
      </h1>
      <h2 class="subtle-subheader d2l-heading-2 onboarding-screen-1-subheader">
        ${this.localize('view-onboarding.getStarted.marquee.subheader')}
      </h2>
    `;
  }

  get _jobTitleTemplate() {
    const highlightedTitle = html`
      <span class="highlighted"> ${this.session?.user?.title}</span>
    `;

    const titleInput = html`
      <span class="title-input-container">
        <d2l-input-text
          id="job-title-input"
          label="${this.localize('view-onboarding.getStarted.currentRoleLabel')}"
          label-hidden
          placeholder=${this.localize('view-onboarding.getStarted.currentRolePlaceholder')}
          @change=${this._setUserTitle}>'
          <d2l-icon slot="right" icon="tier1:check"></d2l-icon>
        </d2l-input-text>
      </span>
    `;

    const nameToDisplay = this.session?.user?.firstName || this.session?.user?.getDisplayName();

    return html`
      <h2 class="d2l-heading-2 job-title-header">
        <span class="hello-string">${this.localize('view-onboarding.getStarted.introduction.subheader', { name: nameToDisplay })}</span>
        ${this._hasTitle ? highlightedTitle : titleInput}
      </h2>
    `;
  }

  get _skillPopperTemplate() {
    const skillpopperLabel = this._relevantSkills?.length > 0
      ? html`<p class="skill-popper-label">${this.localize('view-onboarding.getStarted.skillpopper.label')}</p>`
      : nothing;
    const popperSkills = this._relevantSkills?.map(skill => ({ ...skill, isRelatedToRole: true }));

    return html `
      ${skillpopperLabel}
      <skill-popper .skills=${popperSkills}></skill-popper>
    `;
  }

  get _socTemplate() {
    if (!this._hasTitle) return nothing;
    const titleFoundTemplate = [this._socMatchTemplate, this._skillPopperTemplate, this._submitButtonTemplate];
    const position = window.innerWidth > 1229 ? 'right' : 'top';
    const industryTitle = html`
      <nova-tooltip
        position=${position}
        text=${this.localize('view-onboarding.getStarted.closestMatchLabel.tooltip.header')}
        tooltip-text=${this.localize('view-onboarding.getStarted.closestMatchTooltip')}
        offset="12"
      ></nova-tooltip>
    `;
    return html`
      <div class="soc-container">
        <p class="soc-industry-label d2l-label-text">
          ${this.localize('view-onboarding.getStarted.closestMatchLabel', { industryTitle })}
        </p>
          ${this._editingTitle ? this._socSearchTemplate : titleFoundTemplate }
      </div>
    `;
  }

  get _socMatchTemplate() {
    return html`
      <nova-card class="soc-match-card" no-padding compact>
        <div slot="primary">
          <span>
            <h3 class="d2l-heading-4">${this._truncateString(this.jobTitleName)}</h3>
            ${this.jobTitleSOC ? html`
                <div class="d2l-body-small galena">
                  ${this._truncateString(this.jobTitleSOC, 50)}
                </div>
              ` : nothing}
          </span>
            <nova-button-subtle
            role="button"
            aria-label=${this.localize('view-onboarding.getStarted.editSOC.ariaLabel')}
            text=${this.localize('view-onboarding.getStarted.editSOC.button')}
            @click=${this._handleEditTitleClicked}
            >
          </nova-button-subtle>
        </div>
      </nova-card>
    `;
  }

  get _socSearchTemplate() {
    return html`
      <d2l-labs-autocomplete
        id="auto-complete"
        role="search"
        show-on-focus
        remote-source
        @d2l-labs-autocomplete-filter-change=${this._searchChange}
        @d2l-labs-autocomplete-suggestion-selected=${this.setTitle}>
        <d2l-input-search
          id="soc-search"
          label="Title"
          @keydown=${this._stopInputPropagation}
          @d2l-input-search-searched=${this._searchChange}
          slot="input">
        </d2l-input-search>
      </d2l-labs-autocomplete>
    `;
  }

  get _submitButtonTemplate() {
    return html`
      <d2l-button
        class="submit-button"
        @click=${this._handleSubmission}
        primary>
        ${this.localize('view-onboarding.getStarted.submit.button')}
      </d2l-button>
    `;
  }
}

window.customElements.define('get-started', GetStarted);
