import '@brightspace-ui/core/components/colors/colors.js';
import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/icons/icon-custom.js';
import '@brightspace-ui/core/components/button/button-icon.js';

import { css, html, LitElement, nothing } from 'lit';

import { LocalizeNova } from '../../../../mixins/localize-nova/localize-nova.js';

class SkillChip extends LocalizeNova(LitElement) {

  static get properties() {
    return {
      /* if true, a button will appear on element which fires the 'nova-chip-remove' event when clicked */
      removable: { type: Boolean, reflect: true },
      /* if true, the chip represents a remainder of skills that are not displayed. i.e "+ 15" */
      remainder: { type: Boolean, reflect: true },
      /* skill object passed to element, mainly pulls name of skill */
      skill: { type: Object },
      /* custom 'text' string will be displayed within chip component */
      text: { type: String },
      /* used for providing more context during hover/interaction, defaults to text when not used */
      titleText: { type: String, attribute: 'title-text', reflect: true },
      /* limits the max width of the chip to --skill-chip-truncate-width */
      truncate: { type: Boolean, reflect: true },
      /* private: is true if component is to display an icon */
      _hasIcon: { type: Boolean, attribute: 'has-icon', reflect: true },
    };
  }

  static get styles() {
    return [
      css`
        :host {
          --skill-chip-background-color: var(--d2l-color-sylvite); /* Default background color */
          --skill-chip-font-color: #202122; /* Default font color */
          --skill-chip-font-weight: 400;
          --skill-chip-padding: 12px;
          --skill-chip-truncate-width: 120px;

          align-items: center;
          background-color: var(--skill-chip-background-color);
          border: var(--skill-chip-border);
          border-radius: 6px;
          box-shadow: var(--skill-chip-box-shadow);
          color: var(--skill-chip-font-color); /* Default font color */
          display: inline-block;
          font-size: 14px;
          padding: calc(var(--skill-chip-padding) / 3) var(--skill-chip-padding);
        }

        :host([remainder]) {
          background-color: var(--skill-chip-list-remainder-background-color, var(--d2l-color-sylvite)); /* Default background color */
          color: var(--skill-chip-list-remainder-color, var(--skill-chip-font-color)); /* Default font color */
        }

        :host([removable]) {
          padding-right: calc(var(--skill-chip-padding) / 2);
        }

        :host([has-icon]) {
          padding-left: calc(var(--skill-chip-padding) / 2);
        }

        .flex-container {
          align-items: center;
          display: flex;
          flex-wrap: nowrap;
          line-height: 1.1rem;
        }

        .hype-icon-container {
          margin-right: calc(var(--skill-chip-padding) / 2);
        }

        #hype-icon {
          color: var(--d2l-color-celestine);
          vertical-align: sub;
        }

        /* TODO: Set fill colour for custom svgs */
        #hype-icon.primary {
          color: var(--d2l-color-celestine-minus-1);
        }

        .skill-text {
          flex: 1; /* Allow text to take up remaining space */
          font-size: var(--skill-chip-font-size, 14px);
          font-weight: var(--skill-chip-font-weight);
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        :host([truncate]) .skill-text {
          max-width: var(--skill-chip-truncate-width);
        }

        .remove-button {
          margin-left: calc(var(--skill-chip-padding) / 2);
        }

        d2l-button-icon {
          --d2l-button-icon-fill-color: var(--d2l-color-chromite);
          --d2l-button-icon-min-height: 1.2rem;
          --d2l-button-icon-min-width: 1.2rem;
        }

        @media (max-width: 767px) {
          :host {
            --skill-chip-padding: 6px;

            border-radius: 4px;
            font-size: 12px;
          }

          .skill-text {
            font-size: var(--skill-chip-font-size, 12px);
          }
        }
      `,
    ];
  }

  constructor() {
    super();
    this.removable = false;
    this.skill = null;
    this.text = '';
    this.truncate = false;
    this._hasIcon = null;
  }

  get _icon() {
    if (this.skill?.isInSkillProfile) {
      return this.skill?.isInDemand ? 'skill-target-hybrid' : 'skill-target';
    }
    return this.skill?.isInDemand ? 'accelerator' : '';
  }

  get _iconColorClass() {
    if (this.skill?.isInSkillProfile || this.skill?.wasSearched) return 'primary';
    if (this.skill?.isRelatedToRole && this.skill?.isInDemand) return 'primary';
    return '';
  }

  updated(changedProperties) {
    super.updated();
    if (changedProperties.has('skill')) {
      this._updateChipColors();
    }
  }

  render() {
    if (!this.skill && !this.text) {
      return nothing;
    }
    return this._chipTemplate(this.text || this.skill.name);
  }

  _customSvg(icon) {
    if (icon === 'skill-target') {
      return html`
        <svg id="skill-target" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1523_954)">
            <path d="M17.0485 8.04827H15.7579C15.3309 5.04772 12.9533 2.67403 9.95132 2.25273V0.95152C9.95132 0.427025 9.52429 0 8.9998 0C8.47489 0 8.04828 0.427025 8.04828 0.95152V2.25263C5.04504 2.67362 2.66707 5.04772 2.24004 8.04868H0.95152C0.426611 8.04868 0 8.47571 0 9.0002C0 9.5247 0.426611 9.95172 0.95152 9.95172H2.23632C2.65302 12.9654 5.03678 15.3527 8.04868 15.7747V17.0485C8.04868 17.573 8.47529 18 9.0002 18C9.52511 18 9.95172 17.573 9.95172 17.0485V15.7744C12.9628 15.3516 15.3457 12.9646 15.762 9.9513H17.0485C17.5734 9.9513 18 9.52427 18 8.99978C18 8.47529 17.5734 8.04827 17.0485 8.04827ZM8.99897 13.954C6.27491 13.954 4.05883 11.7375 4.05883 9.01341C4.05883 6.28936 6.27491 4.07328 8.99897 4.07328C11.723 4.07328 13.9395 6.28936 13.9395 9.01341C13.9395 11.7375 11.723 13.954 8.99897 13.954Z" fill="#006FBF"/>
          </g>
          <defs>
            <clipPath id="clip0_1523_954">
              <rect width="18" height="18" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      `;
    } else if (icon === 'skill-target-hybrid') {
      return html`
        <svg id="skill-target-hybrid" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1523_956)">
            <path d="M17.0485 8.04827H15.7579C15.3309 5.04772 12.9533 2.67403 9.95132 2.25273V0.95152C9.95132 0.427025 9.52429 0 8.9998 0C8.47489 0 8.04828 0.427025 8.04828 0.95152V2.25263C5.04504 2.67362 2.66707 5.04772 2.24004 8.04868H0.95152C0.426611 8.04868 0 8.47571 0 9.0002C0 9.5247 0.426611 9.95172 0.95152 9.95172H2.23632C2.65302 12.9654 5.03678 15.3527 8.04868 15.7747V17.0485C8.04868 17.573 8.47529 18 9.0002 18C9.52511 18 9.95172 17.573 9.95172 17.0485V15.7744C12.9628 15.3516 15.3457 12.9646 15.762 9.9513H17.0485C17.5734 9.9513 18 9.52427 18 8.99978C18 8.47529 17.5734 8.04827 17.0485 8.04827ZM8.99897 13.954C6.27491 13.954 4.05883 11.7375 4.05883 9.01341C4.05883 6.28936 6.27491 4.07328 8.99897 4.07328C11.723 4.07328 13.9395 6.28936 13.9395 9.01341C13.9395 11.7375 11.723 13.954 8.99897 13.954Z" fill="#006FBF"/>
          </g>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2218 8.55558C11.2069 8.69444 11.1514 8.82583 11.0622 8.93336L10.876 9.19691L8.33023 12.7969C8.29516 12.8565 8.2457 12.9063 8.18637 12.9418C8.12704 12.9773 8.05975 12.9973 7.99068 13C7.92858 12.9983 7.86773 12.9822 7.81296 12.9528C7.75818 12.9235 7.71099 12.8819 7.67512 12.8311C7.61669 12.7512 7.58552 12.6546 7.58623 12.5556C7.59068 12.4729 7.61112 12.3916 7.64712 12.3169L7.7049 12.1756C7.73645 12.0978 8.63957 9.88891 8.64401 9.88891C8.51557 9.88891 7.28979 9.89602 7.16179 9.88891C7.0605 9.87734 6.96651 9.83054 6.89623 9.75669C6.82596 9.68284 6.78388 9.58664 6.77734 9.48491C6.78623 9.2938 6.86534 9.11336 6.99957 8.97736L9.54357 5.38358C9.61019 5.27289 9.6907 5.17118 9.78312 5.08091C9.82706 5.04765 9.87763 5.02422 9.93142 5.01222C9.9852 5.00022 10.0409 4.99992 10.0948 5.01134C10.1488 5.02276 10.1996 5.04564 10.2439 5.07843C10.2882 5.11121 10.3249 5.15313 10.3516 5.20135C10.3945 5.31665 10.4096 5.44046 10.3957 5.5627C10.3818 5.68495 10.3393 5.80219 10.2716 5.90491L9.41468 8.11113H10.5498C10.6996 8.09292 10.8515 8.10887 10.9942 8.1578C11.1187 8.22447 11.2018 8.34891 11.2165 8.48935C11.22 8.51158 11.2218 8.5338 11.2218 8.55558Z" fill="#006FBF"/>
          <defs>
            <clipPath id="clip0_1523_956">
              <rect width="18" height="18" fill="white"/>
            </clipPath>
            <clipPath id="clip1_1523_956">
              <rect width="8" height="8" fill="white" transform="translate(5 5)"/>
            </clipPath>
          </defs>
        </svg>
      `;
    }
    return nothing;
  }

  _handleRemoveClick() {
    const removeEvent = new CustomEvent('nova-chip-remove', {
      detail: { skill: this.skill },
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(removeEvent);
  }

  _iconTemplate(icon) {
    if (icon === '') {
      this._hasIcon = false;
      return nothing;
    }

    this._hasIcon = true;
    let inner = html`
      <d2l-icon id="hype-icon" class=${this._iconColorClass} icon="tier1:${icon}"></d2l-icon>
    `;

    if (['skill-target', 'skill-target-hybrid'].includes(icon)) {
      inner = html`
        <d2l-icon-custom id="hype-icon" size="tier1" class=${this._iconColorClass}>
          ${this._customSvg(icon)}
        </d2l-icon-custom>
      `;
    }

    return html`
      <span class="hype-icon-container">
        ${inner}
      </span>
    `;
  }

  _removeButtonTemplate() {
    if (this.removable) {
      return html`
        <d2l-button-icon
          class="remove-button"
          @click="${this._handleRemoveClick}"
          icon="tier1:close-small"
          tabindex="-1"
          text=${this.localize('skill-chip.remove-button.text')}>
        </d2l-button-icon>
      `;
    }
    return nothing;
  }

  _chipTemplate(displayText) {
    return html`
      <div class="flex-container" title=${this.titleText ?? displayText}>
        ${this._iconTemplate(this._icon)}
        <p class="skill-text">${displayText}</p>
        ${this._removeButtonTemplate()}
      </div>
    `;
  }

  _updateChipColors() {
    let backgroundColor = 'var(--d2l-color-sylvite)';
    let fontColor = '#202122';
    let fontWeight = '400';
    let boxShadow = 'none';
    let border = 'none';

    // styling was separate at one point, can unite expression if spec makes them officially merged
    if (this.skill?.isRelatedToRole || this.skill?.isInDemand) {
      backgroundColor = 'var(--d2l-color-celestine-plus-2)';
      fontWeight = '700';
    }

    if (this.skill?.isInSkillProfile || this.skill?.wasSearched) {
      backgroundColor = '#C9E8FA';
      fontColor = 'var(--d2l-color-celestine-minus-1)';
      fontWeight = '700';
    }

    if (this.skill?.wasSearched) {
      boxShadow = '0px 2px 4px 0px rgba(0, 0, 0, 0.25)';
      border = '1px solid var(--Core-Colours-Celestine, #006FBF)';
    }

    this.style.setProperty('--skill-chip-background-color', `${backgroundColor}`);
    this.style.setProperty('--skill-chip-border', border);
    this.style.setProperty('--skill-chip-box-shadow', boxShadow);
    this.style.setProperty('--skill-chip-font-color', `${fontColor}`);
    this.style.setProperty('--skill-chip-font-weight', fontWeight);
  }
}

window.customElements.define('skill-chip', SkillChip);
