import { denormalizedActivityAttributes, VALID_TAGS } from '../schema/activity/shared.js';
import ActivitiesHelper from '../../helpers/activities.js';
import { extractDateFromISODateTime } from '../../helpers/dateTime.js';
import { formatCurrencyAsIntWithCode } from '../../helpers/number.js';
import { NovaModel } from '../nova-model.js';
import { NovaSchema } from '../schema/nova-model-schema.js';
import { Tags } from '../../../app/shared/models/tags/tags.js';

const NOCHANGE = ({ attribute }) => attribute;

export const csvAttributeFormatters = {
  id: NOCHANGE,
  cost: NOCHANGE,
  currency: ({ provider }) => provider?.operatingCurrency || '',
  type: NOCHANGE,
  title: ({ attribute }) => attribute.replace(/,/g, ' '),
  belongsToProgramAndOrder: ({ activity }) => (
    (activity.parents?.length)
      ? activity.parents.map(({ title, meta }) => `${title}(${meta?.order || 'N/A'})`).join(':')
      : 'N/A'),
  programCost: ({ activity }) => (activity.parents?.length ? activity.parents?.map(({ cost }) => cost).join(':') : 'N/A'),
  category: ({ attribute }) => attribute.tagValues.join(':'),
  duration: NOCHANGE,
  provider: ({ provider }) => provider?.name || '',
  status: ({ activity }) => (activity.tags.hasTag('active') ? 'active' : 'inactive'),
  instructionLang: NOCHANGE,
  requestableStatus: ({ activity }) => (activity.tags.hasTag('allowRequest') ? 'requestable' : 'not requestable'),
  certificateType: NOCHANGE,
  format: NOCHANGE,
  delivery: NOCHANGE,
  weeklyTimeCommitment: NOCHANGE,
  skills: ({ activity }) => activity?.skills?.map(item => item.name).join(':'),
  startDate: ({ activity }) => {
    if (activity?.startDateType !== 'date') {
      return 'N/A';
    }

    const futureDates = activity?.startDate?.reduce((acc, date) => {
      const formattedDate = extractDateFromISODateTime(date);
      const currentDate = new Date().setHours(0, 0, 0, 0);
      if (formattedDate && new Date(formattedDate) >= currentDate) {
        acc.push(formattedDate);
      }
      return acc;
    }, []);

    return futureDates?.length ? futureDates.join(':') : 'No future start dates available';
  },
  startDateType: ({ activity }) => activity?.startDateType || 'N/A',
};
export const CSV_EXPORTABLE_ATTRIBUTES = Object.keys(csvAttributeFormatters);

class GenericActivitySchema extends NovaSchema {
  constructor() {
    super('genericActivity');
    this.attributes = denormalizedActivityAttributes;
  }
}

const schema = new GenericActivitySchema();

/**
 * This is the base class for all activity types.
 */
export default class GenericActivity extends NovaModel {

  constructor(base = {}, modelType = 'genericActivity') {
    super(modelType, base);
    this.tags = new Tags(VALID_TAGS, base.tags);
    const tempCost = base.tempCost?.cost / 100;
    this.cost = tempCost || tempCost === 0 ? tempCost : '';
    this.category = new Tags(this.getPossibleValues('category').map(attr => attr.value), base.category);
  }

  get formattedCost() {
    return this.hasCost ? formatCurrencyAsIntWithCode(this.tempCost.inDollars()) : '';
  }

  get isAdmissionBased() {
    return !!this.hasTag('admissionBased');
  }

  get admissionRequirementsLength() {
    const { standard, international } = this.getAdmissionRequirements();

    return standard.length + international.length;
  }

  get formattedStartDate() {
    return this.getFormattedValue('startDate');
  }

  get hasCost() {
    return this.tempCost;
  }

  get isLocationBased() {
    return this.delivery === 'inPersonLocationRestricted';
  }

  get isAdmissionsBased() {
    return this.hasTag('admissionBased');
  }

  getSchema() {
    return schema;
  }

  getAdmissionRequirements() {
    const { standard = [], international = [], contactEmail = '' } = this.admissionRequirements || {};
    return { standard, international, contactEmail };
  }

  hasTag(tag) {
    return this.tags.hasTag(tag);
  }

  isScheduled() {
    return this.startDateType !== 'unknown' && this.startDateType !== 'comingSoon' && !this.isPastStartDate();
  }

  isPastStartDate() {
    if (this.startDateType !== 'date') return false;
    const nextStartDate = ActivitiesHelper.getNextSessionDateAsString(Array.isArray(this.startDate) ? this.startDate : [this.startDate]);
    return !nextStartDate;
  }

  setTag(tag, value) {
    return this.tags.setTag(tag, value);
  }

}
