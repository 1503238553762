import { navigator as nav } from 'lit-element-router';

import { css, html } from 'lit';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { RtlMixin } from '@brightspace-ui/core/mixins/rtl-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import Activity from '../../../../shared/models/activity/activity.js';
import ActivityFilter from '../../../shared/models/activity-filter/activity-filter.js';
import { LANDING_STREAM_TYPES } from '../../../../shared/constants.js';
import LandingStream from '../../../shared/models/landing-stream/landing-stream.js';
import { LocalizeNova } from '../../../shared/mixins/localize-nova/localize-nova.js';

export default superclass => class LandingCarouselMixin extends SkeletonMixin(RtlMixin(LocalizeNova(RequesterMixin(nav(superclass))))) {
  static get properties() {
    return {
      _activityIdsToExclude: { type: Object },
      _filter: { type: Object },
    };
  }

  constructor() {
    super();
    this._filter = null;
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host([empty]) {
          display: none;
        }
        .empty-state-container {
          border: 2px solid #e6eaf0;
          border-radius: 12px;
          box-shadow: 2px 2px 10px 2px #0000000d;
          box-sizing: border-box;
          display: block;
          padding: 30px 60px;
          position: relative;
          width: 100%;
        }
      `,
    ];
  }

  /**
   * Returns a reusable empty state template with an error message and action button.
   */
  get _emptyStateTemplate() {
    return html`
      <div class='empty-state-container'>
        <d2l-empty-state-illustrated
          description=${this.localize('view-landing-page.carousel.error.description')}
          title-text=${this.localize('general.error')}>
          <img
            aria-hidden='true'
            src='/assets/img/error-state-search.svg'
            slot='illustration'
          />
          <d2l-empty-state-action-button
            @d2l-empty-state-action=${this._handleEmptyState}
            text=${this.localize('view-landing-page.carousel.error.action')}
          ></d2l-empty-state-action-button>
        </d2l-empty-state-illustrated>
      </div>
    `;
  }

  _handleEmptyState() {
    location.reload();
  }

  /**
   * Method to determine `suffixId`. Components must override this.
   */
  _getSuffixId(selectedId) {
    throw new Error(`_getSuffixId() must be implemented in the component with ${selectedId}.`);
  }

  /**
   * Sets up an Intersection Observer to detect when the component becomes visible.
   * Calls `_setupComponent()` once it enters the viewport and then disconnects the observer.
   */
  async _setupIntersectionObserver() {
    this._observer = new IntersectionObserver(async entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this._setupComponent();
          this._observer.disconnect();
        }
      });
    },
    { threshold: 0.1 }
    );

    this._observer.observe(this);
  }

  /**
   * Checks if all streams in the given array have a status of 'rejected'.
   * @param {Array} streams - Array of stream objects.
   * @returns {boolean} - Returns true if all streams are rejected, otherwise false.
   */
  _areAllStreamsRejected(streams) {
    return streams.every(stream => stream.streamStatus === 'rejected');
  }

  /**
   * Checks if all tab content objects in the provided data have zero hits.
   * @param {Object} data - An object where values contain tab content data.
   * @returns {boolean} - Returns true if all tabs have zero hits, otherwise false.
   */
  _isFetchedDataEmpty(data) {
    return Object.values(data).every(tabContent => {
      if (typeof tabContent === 'object') {
        return tabContent.totalNumberOfHits === 0;
      }
    });
  }

  /**
   * Extracts and processes stream data, handling rejected streams and filtering activities as needed.
   * @param {Object} stream - A stream object containing status and value properties.
   * @returns {Object} - Processed stream data including activities, total hits, and path.
   */
  _getStreamData(stream) {
    const { streamStatus } = stream;

    if (streamStatus === 'rejected') {
      console.error('Fetching activities data failed');
      return { activities: [], totalNumberOfHits: 0, path: '' };
    }

    const { results, path } = stream.value;
    const { hits, total } = results;

    const activities = this._getActivitiesFromHits(hits);
    let totalNumberOfHits = total?.value ?? 0;

    if (stream.value?.id?.includes('best-results')) {
      return { activities: activities.slice(0, 6), totalNumberOfHits, path };
    } else if (this._activityIdsToExclude?.size) {
      const filteredActivities = activities.filter(activity => !this._activityIdsToExclude.has(activity.id)
      );
      const numberOfActivitiesExcluded =
        activities.length - filteredActivities.length;
      totalNumberOfHits -= numberOfActivitiesExcluded;
      return { activities: filteredActivities, totalNumberOfHits, path };
    } else {
      return { activities, totalNumberOfHits, path };
    }
  }

  /**
   * Converts an array of raw activity data (hits) into an array of Activity objects.
   * @param {Array} hits - Array of raw activity data retrieved from the backend.
   * @returns {Array} - Array of instantiated Activity objects.
   */
  _getActivitiesFromHits(hits) {
    return hits.map(act => {
      const activity = new Activity(act);
      return activity;
    });
  }

  /**
   * Determines whether should slice the best results to avoid duplicates.
   * Components can override this if they have different conditions.
   */
  _shouldSliceBestResults() {
    return true;
  }

  async _callOpenSearch(stream, opts = {}) {
    const MAX_TRIES = 3;
    const requestFn = async() => {
      const results = await this.client.searchActivities({
        ...opts,
      });
      if (results.hits !== undefined) {
        return this._getStreamProps(stream, results);
      } else {
        throw new Error(`Search activities failed with error: ${results}`);
      }
    };

    return await this._callOSWithRetries(requestFn, MAX_TRIES);
  }

  _getStreamProps(streamData, results = undefined) {
    return {
      displayName: streamData.displayName,
      subtitle: streamData.subtitle,
      id: streamData.id,
      path: streamData.path,
      type: streamData.type,
      sort: streamData.sort,
      property: streamData.property,
      filters: streamData.filters,
      careerData: streamData.careerData,
      results: results,
    };
  }

  async _callOSWithRetries(requestFn, MAX_TRIES, tries = 0) {
    if (tries >= MAX_TRIES) {
      throw new Error('Maximum retries exceeded');
    }

    try {
      const value = await requestFn();
      return value;
    } catch (error) {
      tries++;
      await this.backoff(tries);
      return await this._callOSWithRetries(requestFn, MAX_TRIES, tries);
    }
  }

  async backoff(attempts) {
    const wait = Math.round(2000 + (1000 * (attempts - 1)));
    await new Promise(resolve => setTimeout(resolve, wait));
  }

  /**
   * Sets up and fetches data streams based on the selected ID and provided filters.
   * @param {string|null} selectedId - The ID used to generate a suffix for stream paths.
   * @param {Object} filter - The filter object used to refine search results.
   */
  async _setupData(selectedId = null, filter) {
    const suffixId = selectedId ? `_${selectedId}` : this._getSuffixId(selectedId);

    const bestResultsStream = new LandingStream({
      id: `${LANDING_STREAM_TYPES.bestResults}/${suffixId}`,
      path: `${LANDING_STREAM_TYPES.interestedGoals}/${LANDING_STREAM_TYPES.bestResults}${suffixId}`,
      filters: filter,
    });
    const microlearningStream = new LandingStream({
      id: `${LANDING_STREAM_TYPES.microlearning}/${suffixId}`,
      path: `${LANDING_STREAM_TYPES.interestedGoals}/${LANDING_STREAM_TYPES.microlearning}${suffixId}`,
      filters: filter,
      property: {
        name: 'tags',
        value: 'fasttocomplete',
      },
    });
    const coursesStream = new LandingStream({
      id: `${LANDING_STREAM_TYPES.courses}/${suffixId}`,
      path: `${LANDING_STREAM_TYPES.interestedGoals}/${LANDING_STREAM_TYPES.courses}${suffixId}`,
      filters: {
        certificateType: ['course'],
        type: ['course'],
        ...filter,
      },
    });
    const shortCredentialsStream = new LandingStream({
      id: `${LANDING_STREAM_TYPES.shortCredentials}/${suffixId}`,
      path: `${LANDING_STREAM_TYPES.interestedGoals}/${LANDING_STREAM_TYPES.shortCredentials}${suffixId}`,
      filters: {
        certificateType: ['microcredential', 'certificate'],
        ...filter,
      },
    });
    const degreesStream = new LandingStream({
      id: `${LANDING_STREAM_TYPES.degrees}/${suffixId}`,
      path: `${LANDING_STREAM_TYPES.interestedGoals}/${LANDING_STREAM_TYPES.degrees}${suffixId}`,
      filters: { certificateType: ['diploma', 'degree'], ...filter },
    });

    const interestedStreams = [
      bestResultsStream,
      microlearningStream,
      coursesStream,
      shortCredentialsStream,
      degreesStream,
    ].map(stream => {
      const queryParams = new URLSearchParams();
      queryParams.append('source', 'url');
      queryParams.append('filters', btoa(JSON.stringify(stream.filters)));
      stream.path = `${stream.path}?${queryParams.toString()}`;
      return stream;
    });

    const promises = interestedStreams.map(async stream => {
      const streamFilters = new ActivityFilter(stream.filters);
      return await this._callOpenSearch(stream, {
        from: 0,
        size: 6,
        filters: streamFilters,
        randomizeOrder: false,
        sort: { raw: '_score' },
        property: stream.property,
      });
    });

    const streams = (await Promise.allSettled(promises)).map(
      ({ status: streamStatus, value }) => ({ streamStatus, value })
    );
    this._streams = streams;

    const bestResults = this._getStreamData(streams[0]);

    if (this._shouldSliceBestResults()) {
      const topThreeBestResults = bestResults.activities?.slice(0, 3);
      this._activityIdsToExclude = new Set(
        topThreeBestResults.map(activity => activity.id)
      );
    }

    const microlearning = this._getStreamData(streams[1]);
    const courses = this._getStreamData(streams[2]);
    const shortCredentials = this._getStreamData(streams[3]);
    const degrees = this._getStreamData(streams[4]);

    this._data = {
      bestResults,
      microlearning,
      courses,
      shortCredentials,
      degrees,
    };
  }

  async _handleMenuChange(e) {
    const { selectedItemId } = e.detail;
    // determine if the selected item is a career title or a skill category
    const isCareerTitleId = this.careerTitles?.find(title => title.jobId === selectedItemId);

    if (isCareerTitleId !== undefined) {
      this._filter = { skills: this._getSkillsForCareerTitle(selectedItemId).map(skill => skill.name).slice(0, 50) };
    } else {
      this._filter = { skills: this._getSkillsForSkillCategory(selectedItemId)?.map(skill => skill.id) };
    }
    await this._setupData(selectedItemId, this._filter);
  }

  _getSkillsForCareerTitle(careerTitleId) {
    const skills = this.careerTitles.find(title => title.jobId === careerTitleId)?.skills;
    return skills;
  }

  _getSkillsForSkillCategory(skillCategoryId) {
    const matchingSkills = this.skills.filter(skill => skillCategoryId === skill?.skill?.subcategory.id);
    return matchingSkills?.map(matchingSkill => matchingSkill.skill);
  }

  async _viewAllClicked(e) {
    const { href } = e.detail;
    this.navigate(href);
  }
};
