import '@brightspace-ui-labs/autocomplete/autocomplete-input-text.js';
import '@brightspace-ui/core/components/tag-list/tag-list.js';
import '@brightspace-ui/core/components/tag-list/tag-list-item.js';

import { css, html, LitElement, nothing } from 'lit';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';

import { LocalizeNova } from '../../../../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaFlowMixin } from '../../../../../../shared/mixins/nova-flow-mixin/nova-flow-mixin.js';

class ClientOnboardingDepartment extends RequesterMixin(NovaFlowMixin(LocalizeNova(LitElement))) {

  static get properties() {
    return {
      lightcastCompanyId: { type: String, attribute: 'lightcast-company-id' },
      _selectedDepartment: { type: String },
      _departmentList: { type: Array },
    };
  }

  static get styles() {
    return [
      selectStyles,
      css`
        :host {
          display: block;
        }
      `,
    ];
  }

  constructor() {
    super();

    this._departmentList = [];
  }

  async connectedCallback() {
    super.connectedCallback();

    this.client = this.requestInstance('d2l-nova-client');
  }

  async updated(changedProperties) {
    if (changedProperties.has('_selectedDepartment')) {
      this.updateData({
        department: this._selectedDepartment,
      });

      this.updateNextEnabled(this._selectedDepartment !== undefined || this._departmentList.length === 0);
    }

    if (changedProperties.has('lightcastCompanyId')) {
      if (this.lightcastCompanyId) {
        const lightcastDepartmentsData = await this.client.lightcastLotCareerArea(this.lightcastCompanyId);
        if (lightcastDepartmentsData.lotCareerAreas.length) {
          this._departmentList = lightcastDepartmentsData.lotCareerAreas;

          this.requestUpdate();
        }
      }

      this.updateNextEnabled(this._departmentList.length === 0);
    }
  }

  _onSelection(e) {
    if (e.target.value) {
      this._selectedDepartment = e.target.value;
    }
  }

  render() {
    return html`
      <div class="client-onboarding-department-description">
        What is your team in charge of? We'll make sure the learning fits to make every day a bigger success. 
      </div>
      <div class="client-onboarding-department-data">
        <select
					aria-label="Choose a department:"
					class="d2l-input-select"
          @input=${this._onSelection}>
          ${this._selectedDepartment ? nothing : html`<option value="">Select a department</option>` }
					${this._departmentList.map(department => html`
            <option>${department}</option> 
          `)}
				</select>
      </div>
    `;
  }

}

window.customElements.define('client-onboarding-department', ClientOnboardingDepartment);
