import { d2lfetch } from 'd2l-fetch/src/index.js';
import { fetchDedupe } from 'd2l-fetch-dedupe';

import { Application } from '../shared/models/application/index.js';
import { ATTRIBUTE_DISPLAY_OPTIONS } from '../shared/models/attribute-display-options.js';
import { createModel } from '../shared/models/models.js';
import { Currency } from '../shared/drivers/currency.js';
import Tenant from '../shared/models/tenant/index.js';
import UserSession from '../shared/models/user-session.js';

function getUrl(route, apiUrl) {
  const baseUrl = new URL(apiUrl === undefined || !apiUrl ? window.location : apiUrl);
  const { href } = new URL(`/api/${route}`, baseUrl);
  return href;
}

export default class Session {

  constructor() {
    d2lfetch.use({ name: 'dedupe', fn: fetchDedupe });
    this.loadSession().then();
    this.relayState = `${window.location.pathname}${window.location.search}`;
  }

  get draftApplication() {
    return this._draftApplication || {};
  }

  set draftApplication(draft) {
    this._draftApplication = new Application(draft);
  }

  get needsOnboarding() {
    return this.featureEnabled('skillOnboarding') && this.user && !this.user?.settings?.selectedTitleId;
  }

  get settings() {
    return this._user.settings || {};
  }

  set settings(value) {
    this._user.settings = {
      ...this._user.settings,
      ...value,
    };
  }

  get tenant() {
    return this._tenant;
  }

  set tenant(tenant) {
    if (this._user.tenantId === tenant.id && tenant instanceof Tenant) {
      this._tenant = tenant;
    } else {
      throw new Error("Session tenant.id must match session user's tenantId");
    }
  }

  get user() {
    return this._user || new UserSession();
  }

  set user(user) {
    if (this._user.userId === user.userId && user instanceof UserSession) {
      this._user = user;
    } else {
      throw new Error('Session userId mismatch');
    }
  }

  get appDetails() {
    return this._appDetails;
  }

  get errors() {
    return this._errors;
  }

  get isFromAuth0() {
    return this._user?.getExtraAttribute('issuer')?.includes('auth0');
  }

  get isHybridCaseByCase() {
    const { approvalModel, budget: { type: budgetType } } = this.tenant;
    return approvalModel === 'hybrid' && budgetType === 'caseByCase';
  }

  get isHybridSession() {
    return this.tenant.approvalModel === 'hybrid';
  }

  get isShowcase() {
    return this.tenant?.hasFeature('marketing');
  }

  get isReadOnly() {
    return this.tenant?.hasFeature('readonly');
  }

  get isPublicPortal() {
    const { publicPortalEnabled } = this._appDetails;
    return !!publicPortalEnabled;
  }

  get isPublicPortalGuest() {
    const { publicPortalEnabled } = this._appDetails;
    return publicPortalEnabled && this._user?.isGuest;
  }

  get loggedIn() {
    return this.user.loggedIn;
  }

  get relayState() {
    return this._relayState;
  }

  set relayState(relayState) {
    // We don't allow the relay state to be /login to avoid some weird looping scenarios
    if (relayState && relayState.startsWith('/login')) {
      this._relayState = '';
    } else {
      this._relayState = relayState;
    }
  }

  get tenantId() {
    return this.user.tenantId || this.appDetails?.tenantId;
  }

  get userGuid() {
    return this.user ? this.user.guid : undefined;
  }

  get userId() {
    return this.user ? this.user.userId : undefined;
  }

  get trendingActivities() {
    return this._trendingActivities;
  }

  clearSession() {
    this._user = null;
    this._tenant = null;
    window.location.reload(true);
  }

  /**
   * This a helper function for sending requests directly to the domain through cloudfront rather than calling the API gateway
   *
   * NOTE: This will NOT work for calls that require authentication - only for unprotected routes.
   *
   * @param path
   * @param options
   * @returns {Promise<undefined|*>}
   */
  domainRequest(path, options) {
    return this.request(path, options, false, false);
  }

  getLoginUrl(id) {
    const idStr = id ? `&id=${id}` : '';
    return getUrl(`login?RelayState=${this.relayState}${idStr}`);
  }

  hasFeature(feature) {
    return this.appDetails?.features[feature];
  }

  // use to check features that are impacted by DR (ie. skillsOnboarding)
  featureEnabled(feature) {
    return this.appDetails?.features[feature] && !this.appDetails?.features['readonly'];
  }

  get loginAttributes() {
    if (this.tenant && !this._loginAttributes) {
      const ctx = { user: this.user };
      const customAttributes = this.tenant?.compileCustomAttributes(ATTRIBUTE_DISPLAY_OPTIONS.LOGIN, ctx);
      if (customAttributes?.errors?.length > 0) console.warn('Missing Attributes', customAttributes.errors);
      this._loginAttributes = customAttributes?.attributes || [];
    }
    return this._loginAttributes;
  }

  async loadSession() {
    try {
      await this._populateAppDetails();
      await this.refreshSession();
      if (this.user.loggedIn) {
        await this._populateTenant();
        if (this._tenant) {
          await this._populateTrendingActivities();
        }
      }
      this.sessionLoaded();
    } catch (err) {
      this.sessionError(err.status);
    }
    document.addEventListener('nova-logout', async() => {
      await this.domainRequest('session', { method: 'DELETE' });
      this.clearSession();
    });
  }

  login(relayState = this.relayState) {
    this.relayState = relayState;
    window.location = this.getLoginUrl();
  }

  readonlyDialog(path) {
    document.dispatchEvent(new CustomEvent('nova-readonly', {
      detail: { path },
      bubbles: true,
      composed: true,
    }));
  }

  publicPortalLogin(path) {
    document.dispatchEvent(new CustomEvent('nova-public-portal-login', {
      detail: { path },
      bubbles: true,
      composed: true,
    }));
  }

  async refreshSession() {
    try {
      const session = await this.domainRequest('session', { headers: { 'cache-control': 'no-cache' } });
      this._user = new UserSession(session);
    } catch (err) {
      // If we get a 401, we are not logged in. We can ignore this error
      if (err.status !== 401) throw err;
    }
  }

  async request(path, options = { headers: {} }, redirectOn401 = true, useApiUrl = true) {
    const details = this.appDetails;
    const apiUrl = useApiUrl ? details.apiUrl : undefined;
    const url = getUrl(path, apiUrl);
    if (this.user?.authorization) {
      if (!options.headers) options.headers = {};
      options.headers.Authorization = `Bearer ${this.user.authorization}`;
    }
    const response = await d2lfetch.fetch(url, { ...options });

    if (response.ok) {
      if (response.status === 204) {
        return;
      }
      const contentDisposition = response.headers.get('content-disposition');
      if (contentDisposition) {
        const cdAttributes = contentDisposition.split(';').map(attr => attr.trim());
        if (cdAttributes.includes('attachment')) {
          await this.processsAttachment(response, cdAttributes);
          return;
        }
      }
      return createModel(await response.json());
    } else {
      if (response.status === 401) {
        if (redirectOn401) this.clearSession();
      } else if (response.status === 405) {
        this.readonlyDialog(path);
      } else if (response.status === 403) {
        this.publicPortalLogin(path);
      } else if (options.method && options.method !== 'GET') {
        this.toast(response);
      }
      throw response;
    }
  }

  async processsAttachment(response, condtentDisposition) {

    let filename = new Date().toISOString().substring(0, 10);
    for (const attribute of condtentDisposition) {
      const [key, value] = attribute.split('=');
      if (key.trim() === 'filename') {
        filename = value.trim();
      }
    }
    const link = document.createElement('a');
    link.href = URL.createObjectURL(await response.blob());
    link.download = filename;
    link.click();
  }

  sessionError(statusCode = null) {
    const readyEvent = new CustomEvent('nova-error-loading', {
      detail: { status: statusCode },
      bubbles: true,
      composed: true,
    });
    document.dispatchEvent(readyEvent);
  }

  sessionLoaded() {
    const readyEvent = new CustomEvent('nova-ready', {
      bubbles: true,
      composed: true,
    });
    document.dispatchEvent(readyEvent);
  }

  appDetailsLoaded() {
    const detailsEvent = new CustomEvent('nova-details-loaded', {
      bubbles: true,
      composed: true,
    });
    document.dispatchEvent(detailsEvent);
  }

  toast({ type, message, noAutoClose, buttonText }) {
    document.dispatchEvent(new CustomEvent('nova-error', {
      detail: { message, type, noAutoClose, buttonText },
      bubbles: true,
      composed: true,
    }));
  }

  async verifyMagicLink(magic) {
    const decodedMagic = await this.domainRequest('login/magic', {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ magic }),
    });
    if (decodedMagic.relayState) {
      this._relayState = decodedMagic.relayState ? decodedMagic.relayState : null;
    }
  }

  async _populateAppDetails() {
    try {
      const res = await d2lfetch.fetch(getUrl('session/details'));
      this._appDetails = await res.json();
      Currency.setUrl(this._appDetails.ccsURL);
      this.appDetailsLoaded();
    } catch (e) {
      console.error('Error fetching app details', e);
    }
  }

  async _populateTenant() {
    const tenant = await this.domainRequest(`tenants/${this._user.tenantId}`, {});
    this._tenant = new Tenant(tenant);
  }

  async _populateTrendingActivities() {
    const trendingActivities = await this.domainRequest(`tenants/${this._user.tenantId}/trendingActivities`, {});
    this._trendingActivities = trendingActivities;
  }

}
