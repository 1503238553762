import { heading2Styles } from '@brightspace-ui/core/components/typography/styles.js';

import { css, html, LitElement } from 'lit';
import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';

class NovaFlowStep extends LocalizeNova(LitElement) {

  static get properties() {
    return {
      title: { type: String },
      step: { type: String },
      nextEnabled: { type: Boolean, attribute: 'next-enabled' },
      skippable: { type: Boolean },
      blockBack: { type: Boolean, attribute: 'block-back' },
      data: { type: Object },
      shouldSkip: { type: Function },
    };
  }

  static get styles() {
    return [
      heading2Styles,
      css`
        :host {
          display: block;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.title = '';
    this.step = 'none';
    this.nextEnabled = false;
    this.skippable = false;
    this.blockBack = false;
    this.data = undefined;

    this.shouldSkip = () => false;
  }

  firstUpdated() {
    this.slot = this.step;
  }

  updated(changedProperties) {
    if (changedProperties.has('nextEnabled') && this.nextEnabled !== undefined) {
      this.dispatchEvent(new CustomEvent('nova-flow-step-next-enabled', {
        detail: {
          step: this.step,
          nextEnabled: this.nextEnabled,
        },
        bubbles: true,
      }));
    }
  }

  render() {
    return html`
      <h2 class="d2l-heading-2">${this.title}</h2>
      <div class="content">
        <slot></slot>
      </div>
    `;
  }

}

window.customElements.define('nova-flow-step', NovaFlowStep);
