
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { css, html, LitElement, nothing } from 'lit';

import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/list/list.js';
import '@brightspace-ui/core/components/list/list-item.js';

import '../../../shared/components/general/app-link/app-link.js';

import { heading1Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { LocalizeNova } from '../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaPermissionMixin } from '../../../shared/mixins/nova-permission-mixin/nova-permission-mixin.js';

class NovaTenantList extends NovaPermissionMixin(LocalizeNova(SkeletonMixin(LitElement))) {
  static get properties() {
    return {
      disableCreate: { type: Boolean, attribute: 'disable-create' },
      tenants: { type: Array },
      type: { type: String },
    };
  }

  static get styles() {
    return [
      heading1Styles,
      css`
      .tenant-list-item app-link {
        z-index: 1;
      }

      .tenant-list-item {
        align-items: center;
        display: grid;
        grid-template-columns: 30% auto;
        width: 100%;
      }
      .create-button {
        margin-bottom: 12px;
      }
    `];
  }

  constructor() {
    super();
    this.tenants = [];
    this.type = 'provider';
    this.disableCreate = false;
  }

  updated(changedProperties) {
    super.updated(changedProperties);
    if (changedProperties.has('tenants')) {
      this.tenants.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    }
  }

  _handleCreateClick() {
    this.navigate(`tenants/add/${this.type}`);
  }

  render() {
    const createButton = this.disableCreate ? nothing : html`
        <d2l-button class="create-button" @click="${this._handleCreateClick}">${this.localize(`view-admin.addButton.${this.type}`)}</d2l-button>
      `;
    return html`
      <h1 class="d2l-heading-1">${this.localize(`view-admin.${this.type}.heading1`)}</h1>
      ${createButton}
      <d2l-list>
        ${this.tenants.map(tenant => html`
        <d2l-list-item>
          <div class="tenant-list-item">
            ${tenant?.imageUrl ? html`
              <img height="25px" alt="${tenant.name}" src="${tenant.imageUrl}">
            ` : html`
              <span>${tenant.name}</span>
            `}
            <app-link d2l-link href="/tenants/${tenant.id}/edit"><div>${tenant.name}</div></app-link>
          </div>
        </d2l-list-item>
      `)}
      </d2l-list>
    `;
  }
}

window.customElements.define('nova-tenant-list', NovaTenantList);
