/**
 * @fileoverview Nova Attribute Picker Item - A LitElement-based component for selecting and managing attributes.
 *
 * Originally created by D2L Corporation as part of the BrightspaceUI/core repository:
 * https://github.com/BrightspaceUI/core
 *
 * Modified by Kevin Wicken, 2025
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at:
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Changes made:
 */
import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/button/button-icon.js';
import '@brightspace-ui/core/components/colors/colors.js';
import '@brightspace-ui/core/components/tooltip/tooltip.js';
import { css, html, LitElement } from 'lit';
import { bodyCompactStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { LocalizeNova } from '../../../../mixins/localize-nova/localize-nova.js';

class NovaAttributePickerItem extends LocalizeNova(LitElement) {
  static get properties() {
    return {
      deletable: { type: Boolean, reflect: true },
      text: { type: String },
      // The error property is set via the parent
      error: { type: Boolean, reflect: true },
    };
  }

  static get styles() {
    return [
      bodyCompactStyles,
      css`
        :host {
          cursor: pointer;
          display: inline-block;
          width: max-content;
        }
        :host(:focus-visible) {
          outline: none;
        }
        .nova-attribute-picker-item-wrapper {
          align-items: center;
          background-color: var(--d2l-color-sylvite);
          border: 1px solid var(--d2l-color-gypsum);
          border-radius: 0.3rem;
          display: flex;
          gap: 0.2rem;
          padding: 0.25rem;
          padding-inline: 0.5rem;
          user-select: none;
        }
        /* When error is true, apply yellow styling */
        :host([error]) .nova-attribute-picker-item-wrapper {
          background-color: #fff9c4; /* Light yellow background */
          border-color: #fbc02d; /* Darker yellow border */
          color: #000000; /* Use dark text for contrast */
        }
        :host([error]) d2l-icon {
          color: #fbc02d; /* Darker yellow color for the alert icon */
        }
        :host([deletable]) .nova-attribute-picker-item-wrapper {
          padding-bottom: 0.15rem;
          padding-inline: 0.5rem 0.1rem;
          padding-top: 0.1rem;
        }
        :host(:hover) .nova-attribute-picker-item-wrapper {
          background-color: var(--d2l-color-gypsum);
          border-color: var(--d2l-color-mica);
          color: var(--d2l-color-ferrite);
        }
        :host(:focus) .nova-attribute-picker-item-wrapper {
          background-color: var(--d2l-color-celestine);
          border: 1px solid var(--d2l-color-celestine-minus-1);
          color: var(--d2l-color-regolith);
        }
        .nova-attribute-picker-item-text {
          -webkit-box-orient: vertical;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-height: normal;
          max-width: 18rem;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
        }
        d2l-tooltip {
          word-break: break-word;
        }
        d2l-button-icon {
          --d2l-button-icon-background-color-hover: var(--d2l-color-mica);
          --d2l-button-icon-fill-color: var(--d2l-color-chromite);
          --d2l-button-icon-fill-color-hover: var(--d2l-color-tungsten);
          --d2l-button-icon-min-height: 1.2rem;
          --d2l-button-icon-min-width: 1.2rem;
        }
        :host(:focus) d2l-button-icon {
          --d2l-button-icon-background-color-hover: var(--d2l-color-celestine-plus-1);
          --d2l-button-icon-fill-color: var(--d2l-color-mica);
          --d2l-button-icon-fill-color-hover: var(--d2l-color-sylvite);
        }
      `,
    ];
  }

  constructor() {
    super();
    this.deletable = false;
    this.error = false;
  }

  firstUpdated(changedProperties) {
    super.firstUpdated(changedProperties);
    this.tabIndex = 0;
  }

  render() {
    return html`
      <div class="nova-attribute-picker-item-wrapper">
        <!-- If error is true, render the alert icon before the text -->
        ${this.error ? html`<d2l-icon icon="tier1:alert"></d2l-icon>` : ''}
        <div id="nova-attribute-picker-item-text" class="d2l-body-compact nova-attribute-picker-item-text">
          ${this.text}
        </div>
        <d2l-button-icon tabindex="-1"
          text="${this.localize('components:attributePicker:removeValue', 'value', this.text)}"
          icon="tier1:close-small"
          ?hidden="${!this.deletable}"
          @click="${this._onDeleteItem}">
        </d2l-button-icon>
      </div>
      <d2l-tooltip for="nova-attribute-picker-item-text" position="top" show-truncated-only offset="16">
        ${this.text}
      </d2l-tooltip>
    `;
  }

  _onDeleteItem() {
    this.dispatchEvent(new CustomEvent('nova-attribute-picker-item-deleted', { bubbles: true, composed: true }));
  }
}

customElements.define('nova-attribute-picker-item', NovaAttributePickerItem);
