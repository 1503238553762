import '@brightspace-ui/core/components/breadcrumbs/breadcrumb.js';
import '@brightspace-ui/core/components/breadcrumbs/breadcrumbs.js';
import '@brightspace-ui/core/components/dialog/dialog.js';
import '@brightspace-ui/core/components/inputs/input-checkbox.js';
import '@brightspace-ui/core/components/inputs/input-date.js';
import '@brightspace-ui/core/components/inputs/input-fieldset.js';
import '@brightspace-ui/core/components/tabs/tab-panel.js';
import '@brightspace-ui/core/components/tabs/tabs.js';

import './tabs/custom-terms/custom-terms.js';
import './tabs/manage-activity-instructions/manage-activity-instructions.js';
import './tabs/manage-budget-approvals/manage-budget-approvals.js';
import './tabs/manage-career-explorer/manage-career-explorer.js';
import './tabs/manage-custom-attributes/manage-custom-attributes.js';
import './tabs/manage-custom-content/manage-custom-content.js';
import './tabs/manage-general/manage-general.js';
import './tabs/manage-provider-finance/manage-provider-finance.js';
import './tabs/manage-users/manage-users.js';
import '../../components/visibility-manager/visibility-manager.js';
import '../../../admin/components/audit/audit-table/audit-table.js';
import '../../../admin/components/login-manager/login-manager.js';
import '../../components/skill-stream-manager/skill-stream-manager.js';
import '../../../shared/components/general/nova-side-panel/nova-side-panel.js';
import '../../../shared/components/general/nova-side-panel-item/nova-side-panel-item.js';
import '../../../shared/components/general/nova-side-panel-item-group/nova-side-panel-item-group.js';
import './admin-permissions/edit-permissions/edit-permissions.js';
import './admin-permissions/edit-roles/edit-roles.js';

import { css, html, LitElement, nothing } from 'lit';
import { heading1Styles, heading2Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';

import { LocalizeNova } from '../../../shared/mixins/localize-nova/localize-nova.js';
import Tenant from '../../../../shared/models/tenant/index.js';

/**
 * Shows a list of the courses
 */
export default class EditTenant extends LocalizeNova(RequesterMixin(nav(LitElement))) {

  static get properties() {
    return {
      params: { type: Object },
      _currentRole: { type: String, attribute: false },
      _providers: { type: Array, attribute: false },
      _tenant: { type: Object, attribute: false },
      _extraBreadcrumb: { type: Object, attribute: false },
    };
  }

  static get styles() {
    return [
      heading1Styles,
      heading2Styles,
      inputLabelStyles,
      selectStyles,
      css`
        d2l-input-text {
          padding-bottom: 1.5rem;
        }

        .d2l-input-select {
          margin-bottom: 1.5rem;
        }

        .header-wrapper {
          border-bottom: 1px solid #cdd5dc;
          width: 100%;
        }
        .login-header {
          margin-bottom: 0;
        }
`,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
    this._extraBreadcrumb = nothing;
  }

  async firstUpdated() {
    this._currentRole = '';

    this._tenant = this.params?.id ?
      await this.client.fetchTenant(this.params.id) :
      new Tenant({ type: this.params.type });

    this._providers = await this.client.listTenants('provider');
  }

  get tabsByTenantType() {
    return {
      employer: [
        { panel: 'general', viewPermissions: 'employer:general:view', updatePermissions: 'employer:general:update' },
        { panel: 'careerExplorer', viewPermissions: 'employer:careerexplorer:view', updatePermissions: 'employer:careerexplorer:update' },
        { panel: 'budgetApprovals', viewPermissions: 'employer:finance:view', updatePermissions: 'employer:finance:update' },
        { panel: 'customContent', viewPermissions: 'employer:howitworks:view', updatePermissions: 'employer:howitworks:update' },
        { panel: 'customAttributes', viewPermissions: 'employer:customattributes:view', updatePermissions: 'employer:customattributes:update' },
        { panel: 'employeeTerms', viewPermissions: 'employer:termsofuse:view', updatePermissions: 'employer:termsofuse:update' },
        { panel: 'faq', viewPermissions: 'employer:faq:view', updatePermissions: 'employer:faq:update' },
        { panel: 'entitlements', viewPermissions: 'employer:entitlements:view', updatePermissions: 'employer:entitlements:update' },
        { panel: 'visibility', viewPermissions: 'employer:visibility:view', updatePermissions: 'employer:visibility:update' },
        { panel: 'enabledStreams', viewPermissions: 'employer:enabledstreams:view', updatePermissions: 'employer:enabledstreams:update' },
        { panel: 'login', viewPermissions: 'employer:login:view', updatePermissions: 'employer:login:update' },
        { panel: 'audit', viewPermissions: 'employer:audit:view', updatePermissions: 'employer:audit:update' },
      ],
      provider: [
        { panel: 'general', viewPermissions: 'provider:general:view', updatePermissions: 'provider:general:update' },
        { panel: 'providerFinance', viewPermissions: 'provider:finance:view', updatePermissions: 'provider:finance:update' },
        { panel: 'customContent', viewPermissions: 'provider:howitworks:view', updatePermissions: 'provider:howitworks:update' },
        { panel: 'customAttributes', viewPermissions: 'provider:customattributes:view', updatePermissions: 'provider:customattributes:update' },
        { panel: 'faq', viewPermissions: 'provider:faq:view', updatePermissions: 'provider:faq:update' },
        { panel: 'activityInstructions', viewPermissions: 'provider:activityinstructions:view', updatePermissions: 'provider:activityinstructions:update' },
        { panel: 'entitlements', viewPermissions: 'provider:entitlements:view', updatePermissions: 'provider:entitlements:update' },
        { panel: 'login', viewPermissions: 'provider:login:view', updatePermissions: 'provider:login:update' },
        { panel: 'audit', viewPermissions: 'provider:audit:view', updatePermissions: 'provider:audit:update' },
      ],
      admin: [
        { panel: 'general', viewPermissions: 'admin:general:view', updatePermissions: 'admin:general:update' },
        { panel: 'login', viewPermissions: 'admin:login:view', updatePermissions: 'admin:login:update' },
        { panel: 'userPermissions', viewPermissions: 'admin:userpermissions:view', updatePermissions: 'admin:userpermissions:update' },
        { panel: 'roles', viewPermissions: 'admin:roles:view', updatePermissions: 'admin:roles:update' },
        { panel: 'audit', viewPermissions: 'admin:audit:view', updatePermissions: 'admin:audit:update' },
      ],
      providerTenant: [
        { panel: 'createTenant', updatePermissions: 'provider:create' },
      ],
      employerTenant: [
        { panel: 'createTenant', updatePermissions: 'employer:create' },
      ],
    };
  }

  get templates() {
    return {
      general: {
        title: this.localize('manage-general.title'),
        template: updatePermissions => html`
        <h2 class="d2l-heading-2">${this.localize('manage-general.title')}</h2>
        <manage-general
          .tenant=${this._tenant}
          .updatePermissions=${updatePermissions}
          @update-tenant=${this._updateTenant}>
        </manage-general>`,
        icon: 'tier1:home',
      },
      createTenant: {
        title: 'Create tenant',
        template: updatePermissions => html`
        <h1 class="d2l-heading-1">Create new ${this.params.type} tenant</h1>
        <manage-general
          .tenant=${this._tenant}
          .updatePermissions=${updatePermissions}
          @update-tenant=${this._updateTenant}
          create-mode>
        </manage-general>`,
        icon: 'tier1:home',
      },
      careerExplorer: {
        title: this.localize('manage-career-explorer.title'),
        template: updatePermissions => html`
        <h2 class="d2l-heading-2">${this.localize('manage-career-explorer.title')}</h2>
        <manage-career-explorer
          .tenant=${this._tenant}
          .updatePermissions=${updatePermissions}
          @update-tenant=${this._updateTenant}>
        </manage-career-explorer>`,
        icon: 'tier1:eportfolio',
      },
      budgetApprovals: {
        title: this.localize('manage-budget-approvals.title'),
        template: updatePermissions => html`
        <h2 class="d2l-heading-2">${this.localize('manage-budget-approvals.title')}</h2>
        <manage-budget-approvals
          .tenant=${this._tenant}
          .updatePermissions=${updatePermissions}
          @update-tenant=${this._updateTenant}>
        </manage-budget-approvals>`,
        icon: 'tier1:lor',
      },
      providerFinance: {
        title: this.localize('manage-provider-finance.title'),
        template: updatePermissions => html`
        <h2 class="d2l-heading-2">${this.localize('manage-provider-finance.title')}</h2>
        <manage-provider-finance
          .tenant=${this._tenant}
          .updatePermissions=${updatePermissions}
          @update-tenant=${this._updateTenant}>
        </manage-provider-finance>`,
        icon: 'tier1:lor',
      },
      customContent: {
        title: this.localize('manage-how-it-works.title'),
        template: updatePermissions => html`
        <h2 class="d2l-heading-2">${this.localize('manage-how-it-works.title')}</h2>
        <manage-custom-content
          itemDisplay="Block"
          type="homepage"
          .tenantId=${this._tenant.id}
          .updatePermissions=${updatePermissions}>
        </manage-custom-content>`,
        icon: 'tier1:blog',
      },
      customAttributes: {
        title: this.localize('manage-custom-attributes.title'),
        template: updatePermissions => html`
        <h2 class="d2l-heading-2">${this.localize('manage-custom-attributes.title')}</h2>
        <manage-custom-attributes
          .userCustomAttributes=${this._tenant.customAttributes}
          .applicationCustomAttributes=${this._tenant.applicationAttributes}
          .tenantId=${this._tenant?.id}
          .updatePermissions=${updatePermissions}>
        </manage-custom-attributes>`,
        icon: 'tier1:manual-run',
      },
      employeeTerms: {
        title: this.localize('manage-custom-terms.title'),
        template: updatePermissions => html`
        <h2 class="d2l-heading-2">${this.localize('manage-custom-terms.title')}</h2>
        <custom-terms
          .employerName=${this._tenant.name}
          .tenantId=${this._tenant.id}
          .updatePermissions=${updatePermissions}>
        </custom-terms>`,
        icon: 'tier1:add-file',
      },
      faq: {
        title: this.localize('manage-faq.title'),
        template: updatePermissions => html`
        <h2 class="d2l-heading-2">${this.localize('manage-faq.title')}</h2>
        <manage-custom-content
          itemDisplay="FAQ"
          type="faq"
          .tenantId=${this._tenant.id}
          .updatePermissions=${updatePermissions}>
        </manage-custom-content>`,
        icon: 'tier1:quizzing',
      },
      activityInstructions: {
        title: this.localize('manage-activity-instructions.title'),
        template: updatePermissions => html`
        <h2 class="d2l-heading-2">${this.localize('manage-activity-instructions.title')}</h2>
        <manage-activity-instructions
          .tenant=${this._tenant}
          .updatePermissions=${updatePermissions}>
        </manage-activity-instructions>`,
        icon: 'tier1:add-file',
      },
      entitlements: {
        title: this.localize('manage-entitlements.title'),
        template: updatePermissions => html`
        <h2 class="d2l-heading-2">${this.localize('manage-entitlements.title')}</h2>
        <manage-users
          type="Entitlement"
          @users-updated=${this._updateEntitlements}
          .users=${this._tenant.entitlements}
          .tenantType=${this._tenant.type}
          .updatePermissions=${updatePermissions}>
        </manage-users>`,
        icon: 'tier1:lock-locked',
      },
      login: {
        title: this.localize('manage-login.title'),
        template: updatePermissions => html`
        <h2 class="d2l-heading-2 d2l-skeletize login-header">${this.localize('manage-login.title')}</h2>
        <login-manager
          tenant-id="${this._tenant.id}"
          .updatePermissions=${updatePermissions}>
        </login-manager>`,
        icon: 'tier1:profile-default',
      },
      visibility: {
        title: this.localize('manage-visibility.title'),
        template: updatePermissions => html`
        ${this._providers ? html`
          <h2 class="d2l-heading-2">${this.localize('manage-visibility.title')}</h2>
          <visibility-manager
            .providers=${this._providers}
            .contextTenant=${this._tenant}
            .updatePermissions=${updatePermissions}>
          </visibility-manager>` : html`Loading`}`,
        icon: 'tier1:visibility-show',
      },
      enabledStreams: {
        title: this.localize('manage-enabled-streams.title'),
        template: updatePermissions => html`
        <h2 class="d2l-heading-2">${this.localize('manage-enabled-streams.title')}</h2>
        <skill-stream-manager
          .contextTenant=${this._tenant}
          .updatePermissions=${updatePermissions}>
        </skill-stream-manager>`,
        icon: 'tier1:checklist',
      },
      audit: {
        title: this.localize('manage-audit.title'),
        template: updatePermissions => html`
        <h2 class="d2l-heading-2">${this.localize('manage-audit.title')}</h2>
        <audit-table
          .tenant=${this.params.id}
          .updatePermissions=${updatePermissions}>
        </audit-table>`,
        icon: 'tier1:check-circle',
      },
      userPermissions: {
        title: this.localize('edit-permissions.userPermissions'),
        template: updatePermissions => html`
        <edit-permissions
          @change-breadcrumb=${this._changeBreadcrumb}
          .updatePermissions=${updatePermissions}>
        </edit-permissions>`,
        icon: 'tier1:lock-locked',
        group: 'permissions',
        groupTitle: 'Permissions',
      },
      roles: {
        title: this.localize('edit-permissions.roles'),
        template: updatePermissions => html`
        <edit-roles
          @change-breadcrumb=${this._changeBreadcrumb}
          .updatePermissions=${updatePermissions}>
        </edit-roles>`,
        icon: 'tier1:group',
        group: 'permissions',
        groupTitle: 'Permissions',
      },
    };
  }

  _changeBreadcrumb(e) {
    this._extraBreadcrumb = e.detail.breadcrumbTemplate;
  }

  _getProcessedTabs(tenantType) {
    const groups = {};
    const type = this.params?.type ? `${this.params.type}Tenant` : tenantType;
    const tabs = this.tabsByTenantType[type];
    const tabsAndGroups = [];

    for (const tabInfo of tabs) {
      const tabData = this.templates[tabInfo.panel];
      const newTabData = {
        ...tabData,
        id: tabInfo.panel,
        type: 'item',
        viewPermissions: tabInfo.viewPermissions,
        updatePermissions: tabInfo.updatePermissions,
        template: tabData.template(tabInfo.updatePermissions),
      };

      if (tabData.group) {
        if (!groups[tabData.group]) {
          groups[tabData.group] = {
            id: tabData.group,
            title: tabData.groupTitle,
            tabs: [],
            type: 'group',
          };
          tabsAndGroups.push(groups[tabData.group]);
        }
        groups[tabData.group].tabs.push(newTabData);
      } else {
        tabsAndGroups.push(newTabData);
      }
    }

    return tabsAndGroups;
  }

  _panelSelected() {
    this._extraBreadcrumb = nothing;
  }

  render() {
    if (!this._tenant || !this.templates) return nothing;
    const tenantType = this._tenant.type; // Get the current tenant type

    const processedTabs = this._getProcessedTabs(tenantType); // Process tabs based on tenant type

    return html`
      <nova-side-panel
        .basePath="/tenants/${this._tenant.id}/edit"
        .selectedPanel="${this.params.panel}"
        @panel-selected="${this._panelSelected}"
        logo="${this._tenant.imageUrl}">
        <d2l-breadcrumbs style="max-width: fit-content">
          <d2l-breadcrumb
            text=${this.localize(`view-admin.${this._tenant.type}.heading1`)}
            href="/admin/${this._tenant.type}">
          </d2l-breadcrumb>
          ${this._extraBreadcrumb}
        </d2l-breadcrumbs>
        <div class="header-wrapper"><h1 class="d2l-heading-1">${this._tenant.name}</h1></div>

        ${processedTabs.map(tab => {
    if (tab.type === 'item') {
      if (!tab.title) return nothing;
      // Render individual side panel items
      return html`
            <nova-side-panel-item
              panel="${tab.id}"
              label="${tab.title}"
              icon="${tab.icon}"
              .viewPermissions="${tab.viewPermissions}"
              .updatePermissions="${tab.updatePermissions}">
              ${tab.template}
            </nova-side-panel-item>
          `;
    } else if (tab.type === 'group') {
      // Render grouped side panel items
      return html`
            <nova-side-panel-item-group
              expanded
              label="${tab.title}"
              icon="tier1:lock-locked">
              ${tab.tabs.map(groupedTab => html`
                <nova-side-panel-item
                  panel="${groupedTab.id}"
                  label="${groupedTab.title}"
                  .viewPermissions="${groupedTab.viewPermissions}"
                  .updatePermissions="${groupedTab.updatePermissions}">
                  ${groupedTab.template}
                </nova-side-panel-item>
              `)}
            </nova-side-panel-item-group>
          `;
    }
  })}
      </nova-side-panel>
    `;
  }

  async _updateEntitlements(e) {
    const updatedTenant = await this.client.setEntitlements(this._tenant.id, e.detail.users);
    this._tenant.entitlements = { ...updatedTenant.entitlements };
  }

  _updateTenant(e) {
    const { tenant } = e.detail;
    this._tenant = new Tenant(tenant);
  }
}

window.customElements.define('edit-tenant', EditTenant);
