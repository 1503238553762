import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/button/button-icon';
import '@brightspace-ui/core/components/button/button-subtle.js';

import { css, html, LitElement } from 'lit';
import { MobxReactionUpdate } from '@adobe/lit-mobx';
import { reaction } from 'mobx';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';
import { store } from '@app/Store/store.js';

import { Actions } from '@app/Store/constants.js';
import Activity from '../../../../shared/models/activity/activity.js';
import { LocalizeNova } from '../../../shared/mixins/localize-nova/localize-nova.js';

class MyList extends LocalizeNova(SkeletonMixin(RequesterMixin(MobxReactionUpdate(LitElement)))) {

  static get properties() {
    return {
      isWideCardComponent: { type: Boolean },
      isMobileViewActivity: { type: Boolean },
      isNonRequestableCourse: { type: Boolean },
      hideButton: {
        type: Boolean,
        reflect: true,
        attribute: 'hide-button',
      },
      handlePropagation: { type: Boolean },
      activity: { type: Object, attribute: false },
      _savedToMyList: { type: Boolean, attribute: false },
      _stateUpdated: { type: Boolean, attribute: false },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .my-list-button {
          align-items: center;
          display: flex;
          justify-content: flex-end;
          margin-top: -3px;
        }

        :host([hide-button]) .my-list-button {
          display: none;
        }

        @media (max-width: 767px) {
          .full-button-mobile {
            display: flex;
            justify-content: center;
            margin-top: 8px;
          }

          .non-requestable-course {
            margin-top: 20px;
          }
        }

        @media (max-width: 615px) {
          .non-requestable-course {
            margin-top: 12px;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this.activity = new Activity(this.activity);
    this.hideButton = false;
    this.handlePropagation = false;
    this.isMobileViewActivity = false;
    this.isNonRequestableCourse = false;
    this.isWideCardComponent = false;
    this._savedToMyList = false;
    this._stateUpdated = false;
    reaction(
      () => store.getState(Actions.MY_LIST),
      () => {
        this._stateUpdated = true;
        this.updated();
      }
    );

  }

  async updated() {
    if (!this.client || !this.session) return;
    if (!this._stateUpdated) {
      this._savedToMyList = this.session?.user?.getSetting('myList')?.includes(this.activity?.id);
    } else {
      const myListFromState = await store.getState(Actions.MY_LIST);
      this._savedToMyList = myListFromState.includes(this.activity?.id);
      this._stateUpdated = false;
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  get myListText() {
    return this._savedToMyList ? 'view-activity.removeFromMyList' : 'view-activity.addToMyList';
  }

  get myListIcon() {
    return this._savedToMyList ? 'tier2:subscribe-filled' : 'tier2:subscribe-hollow';
  }

  async _handleButtonKeyDown(e) {
    if (!this.handlePropagation) return;
    e.stopPropagation();
    if (e.key === 'Enter' || e.key === ' ') {
      await this._updateMyList(e);
    }
  }

  async _updateMyList(e) {
    if (this.handlePropagation) e.stopPropagation();
    try {
      const updatedActivities = await this.client.toggleMyListActivity(this.activity?.id);
      const updatedMyList = updatedActivities?.settings?.myList ?? [];
      this.session.user.setSetting('myList', updatedMyList);
      store.setState(Actions.MY_LIST, updatedMyList);
      this._savedToMyList = !this._savedToMyList;
    } catch (_event) {
      if (_event?.status !== 405) {
        console.error(_event);
        this.session.toast({ type: 'critical', message: this.localize('view-activity.myListError') });
      }
      return;
    }
    const message = this._savedToMyList ? this.localize('view-activity.toast.added') : this.localize('view-activity.toast.removed');
    this.session.toast({ type: 'default', message, noAutoClose: false });
  }

  get containerClass() {
    if (this.handlePropagation) return '';
    if (this.isMobileViewActivity) {
      let classes = 'my-list-button full-button-mobile';
      if (this.isNonRequestableCourse) classes = `${classes} non-requestable-course`;
      return classes;
    } else {
      return 'my-list-button';
    }
  }

  _renderIconButton() {
    return html`
      <div class=${this.containerClass}>
        <d2l-button-icon
          translucent
          icon=${this.myListIcon}
          text=${this.localize(this.myListText)}
          @keydown=${this._handleButtonKeyDown}
          @click=${this._updateMyList}>
        </d2l-button-icon>
      </div>
    `;
  }

  _renderFullButton() {
    return html`
      <div class=${this.containerClass}>
        <d2l-button-subtle
          icon=${this.myListIcon}
          text=${this.localize(this.myListText)}
          @keydown=${this._handleButtonKeyDown}
          @click=${this._updateMyList}>
        </d2l-button-subtle>
      </div>
    `;
  }

  render() {
    return this.isWideCardComponent ? this._renderIconButton() : this._renderFullButton();
  }
}

window.customElements.define('my-list', MyList);
