import '@brightspace-ui/core/components/empty-state/empty-state-action-button.js';
import '@brightspace-ui/core/components/empty-state/empty-state-illustrated.js';

import { css, html, LitElement, nothing } from 'lit';
import { MobxReactionUpdate } from '@adobe/lit-mobx';
import { navigator as nav } from 'lit-element-router';
import { reaction } from 'mobx';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';
import { store } from '@app/Store/store.js';

import '../nova-carousel/nova-carousel.js';
import { Actions } from '@app/Store/constants.js';
import ActivitiesHelper from '../../../../../shared/helpers/activities.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

class TakeActionTodayCarousel extends LocalizeNova(SkeletonMixin(nav(RequesterMixin(MobxReactionUpdate(LitElement))))) {

  static get properties() {
    return {
      _tabsContent: { type: Array },
      _errorState: { type: Boolean },
      _visibleActivities: { type: Array },
      hasMyListUpdated: { type: Boolean },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .empty-state-container {
          border: 2px solid #e6eaf0;
          border-radius: 12px;
          box-shadow: 2px 2px 10px 2px #0000000d;
          box-sizing: border-box;
          display: block;
          padding: 30px 60px;
          position: relative;
          width: 100%;
        }
`,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.dispose();
  }

  async updateMyList() {
    const updatedMyList = await store.getState(Actions.MY_LIST);
    const myListActivities = updatedMyList.map(activityId => {
      return this._visibleActivities.find(activity => activity.id === activityId);
    });
    const myListTab = this._tabsContent.find(tab => tab.path.includes('myList'));

    // add to list if it's already in a tab & will have content
    if (myListTab && myListActivities.length > 0) {
      myListTab.content = [...myListActivities];
      myListTab.totalActivitiesInList = myListActivities.length;
      this._tabsContent = [...this._tabsContent]; // necessary in order to trigger a downstream rerender
    // if there isn't a tab we should make one
    } else if (myListActivities.length > 0) {
      this._tabsContent = [...this._tabsContent, {
        tabTitle: this.localize('take-action-today-carousel.myListTab'),
        content: myListActivities,
        totalActivitiesInList: myListActivities.length,
        path: 'myList',
      }];
    // if there are no activities in the list & we have a tab already, remove the tab
    } else {
      const updatedTabsContent = this._tabsContent.filter(tab => !tab.path.includes('myList'));
      this._tabsContent = [...updatedTabsContent];
    }
    this.hasMyListUpdated = false;
  }

  async firstUpdated() {
    try {
      const skillProfile = await this.client.getSkillProfile(this.session.user.tenantId, this.session.user.guid);
      const userSkills = new Set(skillProfile.skills.map(skill => skill.skillId));
      const [continueYourProgramActivities, visibleActivities] = await Promise.all([
        this.client.callWithRetries(() => this.client.getInProgressActivities()),
        this.client.callWithRetries(() => this.client.activityList()),
      ]);

      this._visibleActivities = visibleActivities;
      const myListActivities = [], onDemandActivities = [], startingSoonActivities = [];
      const myList = new Set(this.session?.settings?.myList ?? []);
      const visibleActivitySkillsMap = {};

      this._visibleActivities?.map(activity => {
        visibleActivitySkillsMap[activity.id] = new Set(activity.skills.map(skill => skill.id));

        if (myList.has(activity.id)) {
          myListActivities.push(activity);
        }

        if (activity.startDateType === 'anytime') {
          const activitySkills = visibleActivitySkillsMap[activity.id];
          const overlap = activitySkills?.intersection(userSkills) ?? new Set();
          if (overlap.size > 0) {
            onDemandActivities.push(activity);
          }
        } else if (activity.startDateType === 'date' && activity.startDate) {
          const nextStartDate = ActivitiesHelper.getNextSessionDateAsString(activity.startDate);
          const activitySkills = visibleActivitySkillsMap[activity.id];
          const overlap = activitySkills?.intersection(userSkills) ?? new Set();
          if (nextStartDate && new Date(nextStartDate) < new Date(Date.now() + 1669895858) && overlap.size > 0) {
            startingSoonActivities.push(activity);
          }
        }
      });

      const sortByOverlap = (a, b) => {
        const aOverlap = visibleActivitySkillsMap[a.id]?.intersection(userSkills) ?? new Set();
        const bOverlap = visibleActivitySkillsMap[b.id]?.intersection(userSkills) ?? new Set();
        return bOverlap.size - aOverlap.size;
      };

      const sortedOnDemandActivities = onDemandActivities.sort(sortByOverlap);
      const sortedStartingSoonActivities = startingSoonActivities.sort(sortByOverlap);

      const tabContent = [];
      if (continueYourProgramActivities.length > 0) {
        tabContent.push({
          tabTitle: this.localize('take-action-today-carousel.continueYourProgramTab'),
          content: continueYourProgramActivities,
          totalActivitiesInList: continueYourProgramActivities.length,
          path: 'in-progress',
        });
      }

      if (sortedStartingSoonActivities.length > 0) {
        tabContent.push({
          tabTitle: this.localize('take-action-today-carousel.startingSoonTab'),
          content: sortedStartingSoonActivities,
          totalActivitiesInList: sortedStartingSoonActivities.length,
          path: 'starting-soon',
          source: userSkills,
        });
      }

      if (myListActivities.length > 0) {
        tabContent.push({
          tabTitle: this.localize('take-action-today-carousel.myListTab'),
          content: myListActivities,
          totalActivitiesInList: myListActivities.length,
          path: 'myList',
        });
      }

      if (sortedOnDemandActivities.length > 0) {
        tabContent.push({
          tabTitle: this.localize('take-action-today-carousel.onDemandTab'),
          content: sortedOnDemandActivities,
          totalActivitiesInList: sortedOnDemandActivities.length,
          path: 'on-demand',
          source: userSkills,
        });
      }

      const tabsContent = tabContent.map(tab => {
        const arrayFromSource = tab.source ? Array.from(tab.source) : [];
        const queryParams = new URLSearchParams();

        if (arrayFromSource.length > 0) {
          const filtersObject = { skills: arrayFromSource };
          queryParams.append('filters', btoa(JSON.stringify(filtersObject)));
        }

        const queryString = queryParams.toString();
        tab.path = `${tab.path}?source=${queryString ? `url&${queryString}` : 'none'}`;

        return tab;
      });

      this._tabsContent = tabsContent;
    } catch (error) {
      console.error(error);
      this._errorState = true;
    }

    this.skeleton = false;
  }

  async _viewAllClicked(e) {
    const href = e.detail.href;
    this.navigate(href);
  }

  constructor() {
    super();
    this.skeleton = true;
    this._tabsContent = [];
    this._errorState = false;
    this._visibleActivities = [];
    this.hasMyListUpdated = false;
    this.dispose = reaction(
      () => store.getState(Actions.MY_LIST),
      () => {
        this.hasMyListUpdated = true;
      }
    );
  }

  async updated(changedProperties) {
    super.updated(changedProperties);
    if (changedProperties.has('hasMyListUpdated')) {
      await this.updateMyList();
    }
  }

  get _emptyStateTemplate() {
    return html`
      <div class="empty-state-container">
        <d2l-empty-state-illustrated
          description=${this.localize('view-landing-page.carousel.error.description')}
          title-text=${this.localize('general.error')}
        >
          <img aria-hidden="true" src="/assets/img/error-state-search.svg" slot="illustration">
          <d2l-empty-state-action-button
            @d2l-empty-state-action=${this._handleEmptyState}
            text=${this.localize('view-landing-page.carousel.error.action')}
          ></d2l-empty-state-action-button>
        </d2l-empty-state-illustrated>
      </div>
    `;
  }

  render() {
    if (!this.skeleton && this._tabsContent.length === 0 && !this._errorState) {
      this.toggleAttribute('hidden', true);
      return nothing;
    } else if (this.skeleton) {
      return html`
      <nova-carousel
        card-type="activity-hero-card"
        heading=${this.localize('general.loading')}
        max-cards-per-slide="1"
        skeleton
        ></nova-carousel>`;
    } else if (this._errorState) {
      return this._emptyStateTemplate;
    }

    return html`
      <nova-carousel
        card-type="activity-hero-card"
        heading=${this.localize('take-action-today-carousel.heading')}
        @view-all-clicked=${this._viewAllClicked}
        .tabsContent=${this._tabsContent}
        ?skeleton=${this.skeleton}
      ></nova-carousel>
    `;
  }

  _handleEmptyState() {
    location.reload();
  }
}

customElements.define('take-action-today-carousel', TakeActionTodayCarousel);
