// For building REs for Playwright tests. It's hard to add /i when building
// an RE from string variables, so make it explicit here.
export const uuidRegexInfix = '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}';

export const isUuid = str => {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(str);
};

export const base36IdRegex = '^[0-9A-Z]{4,}$';

export const isBase36Id = str => {
  return base36IdRegex.test(str);
};

// eslint-disable-next-line
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const isEmail = email => {
  if (!email) return false;
  return emailRegex.test(email.toLowerCase());
};

const unsignedIntRegex = /^\d+$/;
export const isUnsignedInt = n => {
  return !!unsignedIntRegex.test(n);
};

export const createUrlWithParams = (baseUrl, data) => {
  const params = Object.keys(data).map(key => {
    if (data[key] !== undefined && data[key] !== null) {
      const value = typeof data[key] === 'object' ? JSON.stringify(data[key]) : data[key];
      return [key, value].map(encodeURIComponent).join('=');
    }
    return null;
  }).filter(Boolean).join('&').toString();
  return params.length <= 0 ? baseUrl : `${baseUrl}?${params}`;
};

export const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = (lat2 - lat1) * (Math.PI / 180); // Difference in latitude in radians
  const dLon = (lon2 - lon1) * (Math.PI / 180); // Difference in longitude in radians

  const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers
  return Math.round(distance);
};

export const hasNoContent = thing => {
  if (thing === undefined || thing === null) return true;
  if (typeof thing === 'string' && thing.trim().length === 0) return true;
  if (Array.isArray(thing) && thing.length === 0) return true;
  if (typeof thing === 'object' && Object.keys(thing).length === 0) return true;
  return false;
};
