
import '@brightspace-ui/core/components/filter/filter.js';
import '@brightspace-ui/core/components/filter/filter-dimension-set.js';
import '@brightspace-ui/core/components/filter/filter-dimension-set-value.js';

import { heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';

import { css, html, LitElement } from 'lit';
import { ActivitySchema } from '../../../../../../../shared/models/schema/activity/index.js';
import { LocalizeNova } from '../../../../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaFlowMixin } from '../../../../../../shared/mixins/nova-flow-mixin/nova-flow-mixin.js';

const focusCategories = [
  {
    id: 'certificateType',
    name: 'Certificate Type',
  },
  {
    id: 'format',
    name: 'Format',
  },
  {
    id: 'instructionLang',
    name: 'Language',
  },
  {
    id: 'duration',
    name: 'Duration',
  },
];

class ClientOnboardingFocus extends NovaFlowMixin(LocalizeNova(LitElement)) {

  static get styles() {
    return [
      heading3Styles,
      css`
        :host {
          display: block;
        }
      `,
    ];
  }

  constructor() {
    super();

    this._selectedFocus = {};
  }

  _onfilterChange(e) {
    const data = e.detail;

    if (data.allCleared) {
      this._selectedFocus = {};
    } else {
      data.dimensions.forEach(dimension => {
        if (dimension.cleared) {
          delete this._selectedFocus[dimension.dimensionKey];
        } else {
          dimension.changes.forEach(change => {
            if (change.selected) {
              this._selectedFocus[dimension.dimensionKey] = change.valueKey;
            } else if (this._selectedFocus[dimension.dimensionKey] === change.valueKey) {
              delete this._selectedFocus[dimension.dimensionKey];
            }
          });
        }
      });
    }

    this.updateData(this._selectedFocus);
  }

  _renderCategory(category) {
    const categoryTitle = category.name;
    const categoryOptions = ActivitySchema.getPossibleValues(category.id);

    return html`
      <h3 class="d2l-heading-3">${categoryTitle}</h3>
      <d2l-filter @d2l-filter-change=${this._onfilterChange}>
        <d2l-filter-dimension-set key="${category.id}" text="${categoryTitle}" selection-single search-type="none">
            ${categoryOptions.map(categoryOption => html`
              <d2l-filter-dimension-set-value key="${categoryOption.value}" text="${categoryOption.displayName}"></d2l-filter-dimension-set-value>
            `)}
        </d2l-filter-dimension-set>
      </d2l-filter>
    `;
  }

  render() {
    return html`
      <div class="client-onboarding-focus-description">
        Tell us what matters most to your team-outcomes, formats, or focus areas. We'll make sure to find courses that align.
      </div>
      <div class="client-onboarding-focus-options">
        ${focusCategories.map(category => this._renderCategory(category))}
      </div>
    `;
  }

}

window.customElements.define('client-onboarding-focus', ClientOnboardingFocus);
