import '@brightspace-ui/core/components/empty-state/empty-state-action-button.js';
import '@brightspace-ui/core/components/empty-state/empty-state-illustrated.js';

import { html, LitElement, nothing } from 'lit';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { RtlMixin } from '@brightspace-ui/core/mixins/rtl-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import LandingCarouselMixin from '../landing-carousel-mixin.js';
import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';

import '../../../../shared/components/general/nova-carousel/nova-carousel.js';

class CareerSetsCarousel extends LandingCarouselMixin(SkeletonMixin(RtlMixin(RequesterMixin(nav(LocalizeNova(LitElement)))))) {

  static get properties() {
    return {
      careerTitles: { type: Array }, // the selected career titles
      heading: { type: String },
      empty: { type: Boolean, reflect: true }, // Reflected when the carousel is empty
      _data: { type: Object },
      _streams: { type: Array },
      _filter: { type: Object },
    };
  }

  constructor() {
    super();
    this.careerTitles = [];
    this.heading = '';
    this.skeleton = true;
    this.empty = false;
    this._data = null;
    this._filter = null;
    this._streams = [];
  }

  async connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
    this._setupIntersectionObserver();
  }

  async disconnectedCallback() {
    super.disconnectedCallback();
    if (this._observer) {
      this._observer.disconnect();
    }
  }

  async updated(changedProperties) {
    super.updated();
    if (!this.skeleton && (changedProperties.has('careerTitles'))) {
      await this._setupComponent();
    }
    this.empty = !this.session?.user.getSetting('selectedTitleId') || this.careerTitles.length === 0;
  }

  render() {
    if (this.empty) return nothing;
    return html`
      ${this._carouselTemplate}
    `;
  }

  get _carouselTemplate() {
    if (this.skeleton) {
      return html`
        <nova-carousel
          card-type="activity-card-wide"
          heading=${this.localize('general.loading')}
          max-cards-per-slide="3"
          skeleton>
        </nova-carousel>
      `;
    }

    if (!this._data !== null) {
      if (this._areAllStreamsRejected(this._streams)) {
        return this._emptyStateTemplate;
      }
      if (this._isFetchedDataEmpty(this._data)) {
        return nothing;
      }
    }

    const stylizedCareerTitles = this.careerTitles?.map(title => ({
      id: title.jobId,
      name: `${title.jobName}`,
    }));

    const { bestResults, microlearning, courses, shortCredentials, degrees } = this._data;
    const tabsContent = [
      {
        tabTitle: this.localize(
          'view-landing-page.carousel.tabTitle.bestResults'
        ),
        content: bestResults.activities,
        path: bestResults.path,
      },
      {
        tabTitle: this.localize(
          'view-landing-page.carousel.tabTitle.microlearning'
        ),
        content: microlearning.activities,
        path: microlearning.path,
      },
      {
        tabTitle: this.localize('view-landing-page.carousel.tabTitle.courses'),
        content: courses.activities,
        path: courses.path,
      },
      {
        tabTitle: this.localize(
          'view-landing-page.carousel.tabTitle.shortCredentials'
        ),
        content: shortCredentials.activities,
        path: shortCredentials.path,
      },
      {
        tabTitle: this.localize('view-landing-page.carousel.tabTitle.degrees'),
        content: degrees.activities,
        path: degrees.path,
      },
    ];

    const headingMenu = {
      id: 'career-sets',
      displayText: this.careerTitles ? stylizedCareerTitles[0].name : undefined,
      defaultItem: this.careerTitles ? stylizedCareerTitles[0] : undefined,
      label: this.localize('view-landing-page.carousel.interestedCareerSet.menuLabel'),
      items: stylizedCareerTitles,
    };

    return html`
      <nova-carousel
        card-type="activity-card-wide"
        ?skeleton=${this.skeleton}
        heading=${this.heading}
        max-cards-per-slide="3"
        @menu-changed=${this._handleMenuChange}
        @view-all-clicked=${this._viewAllClicked}
        .tabsContent=${tabsContent}
        .headingMenu=${headingMenu}
      >
      </nova-carousel>
    `;
  }

  async _setupComponent() {
    this.skeleton = true;
    const skillsFilter = this.careerTitles[0]?.skills.map(skill => skill.name).slice(0, 50);
    await this._setupData(null, { skills: skillsFilter });
    this.skeleton = false;
  }

  _getSuffixId(selectedCareerGoalId) {
    return selectedCareerGoalId ? `_${selectedCareerGoalId}` : `_${this.careerTitles[0]?.jobId}`;
  }

}

window.customElements.define('career-sets-carousel', CareerSetsCarousel);
