import '@brightspace-ui/core/components/colors/colors.js';
import '../../../../shared/components/skills/skill-chip-list/skill-chip/skill-chip.js';
import { css, html, LitElement, nothing } from 'lit';
import { repeat } from 'lit/directives/repeat.js';

import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';

export default class SkillPopper extends LocalizeNova(LitElement) {

  static get properties() {
    return {
      skills: { type: Array }, // each skill object should have at least the id & name, e.g. { id: '1234', name: 'Accounting' }
      noAnimation: { type: Boolean, attribute: 'no-animation', reflect: true },
      _skills: { type: Array },
      _animated: { state: true },
    };
  }

  static get styles() {
    return [
      css`
        :host {
          /** these are vars because the canvas needs them */
          --skill-font-size: 14px;
          --skill-font-weight: 700;
          --skill-font-family: "Lato", sans-serif;
          --skill-x-padding: 12px;
          --skill-letter-spacing: 0.2px;

          font-size: var(--skill-font-size);
          line-height: 1.1rem;
        }

        .skill-list {
          display: flex;
          flex-flow: row wrap;
          gap: 6px;
          margin: 0;
          max-width: 100%;
          padding: 0;
        }

        .skill-list-item {
          background-color: var(--d2l-color-celestine-plus-2);
          border-radius: 6px;
          box-sizing: border-box;
          display: inline-block;
          overflow: hidden;
          padding: 4px var(--skill-x-padding);
          transition: max-width 1s ease-out;
        }

        .skill-text {
          font-weight: var(--skill-font-weight);
          letter-spacing: var(--skill-letter-spacing);
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .slide-in {
          animation: SlideIn 0.25s ease-in both;
        }

        :host([no-animation]) .slide-in {
          animation-name: none;
        }

        @keyframes SlideIn {
          0% {
            opacity: 0;
            transform: translateX(-1rem) scale(0.75);
          }
          85% {
            opacity: 1;
            transform: scale(1.1);
          }
          100% {
            transform: scale(1) translateX(0);
          }
        }

        .loading-container {
          align-items: center;
          display: inline-flex;
          gap: 4px;
          justify-content: center;
        }

        .dot {
          animation: Bounce 1500ms ease-out infinite;
          background: var(--d2l-color-celestine);
          border-radius: 50%;
          display: inline-block;
          height: 0.4rem;
          width: 0.4rem;
        }

        .second.dot {
          animation-delay: 150ms;
        }

        .third.dot {
          animation-delay: 300ms;
        }

        @keyframes Bounce {
          0% { transform: none; }
          50% { transform: none; }
          75% { transform: translateY(-0.25rem); }
          100% { transform: none; }
        }

        @media (max-width: 767px) {
          .skill-list-item {
            border-radius: 4px;
            font-size: 12px;
          }

          .dot {
            height: 0.3rem;
            width: 0.3rem;
          }
        }
`,
    ];
  }

  updated(changedProps) {
    super.updated(changedProps);
    for (const [propName] of changedProps) {
      if (propName === 'skills' && this.skills?.length > 0) {
        if (this.noAnimation) {
          this._skills = this.skills;
        } else {
          this._slowlyAddSkills();
        }
      }
    }
  }

  get _loadingTemplate() {
    return html`
      <div
      class="loading-container">
        <div class="dot"></div>
        <div class="second dot"></div>
        <div class="third dot"></div>
      </div>
    `;
  }

  constructor() {
    super();
    this.skills = [];
    this._skills = null;
    if (this._prefersReducedMotion) this.noAnimation = true;
  }

  get _prefersReducedMotion() {
    return !!window.matchMedia('(prefers-reduced-motion: reduce)')?.matches;
  }

  _slowlyAddSkills() {
    this._skills = [];
    clearInterval(this._skillAddInterval);
    clearInterval(this._skillStopLoadingInterval);

    const finishLoadingSkill = index => {
      this._skills[index].isLoading = false;
      this.requestUpdate();
    };

    const intervalDuration = 1900; // add a skill tag in loading state every this many ms
    const loadingDuration = 1950; // disable the loading state for each skill after this many ms

    const startLoadingSkill = () => {
      const currentIndex = this._skills?.length;
      this._skills.push({ ...this.skills[currentIndex], isLoading: true });
      this.requestUpdate();

      if (this._skills.length === this.skills.length) {
        clearInterval(this._skillAddInterval);
      }

      // Finish loading skill
      setTimeout(() => finishLoadingSkill(currentIndex), loadingDuration);
    };

    startLoadingSkill();
    this._skillAddInterval = setInterval(startLoadingSkill, intervalDuration);
  }

  _skillTextTemplate(skill) {
    return html`<p title=${skill.name} class="skill-text">${skill.name}</p>`;
  }

  get _chipListTemplate() {
    return repeat(this._skills,
      skill => skill.id,
      skill => {
        const style = skill.isLoading ? 'max-width: 3rem' : `max-width:${this._getMaxWidth(skill.name)}`;
        return html`
          <li style=${style} class="skill-list-item slide-in">
            ${skill.isLoading ? this._loadingTemplate : this._skillTextTemplate(skill)}
          </li>
        `;
      });
  }

  render() {
    if (!this._skills) return nothing;
    return html`
      <ul class="skill-list" aria-live="off">
        ${this._chipListTemplate}
      </ul>
    `;
  }

  // Gets max-width which is used for animating width change after load
  _getMaxWidth(text) {
    if (!this.textCanvas) this.textCanvas = document.createElement('canvas');
    const context = this.textCanvas.getContext('2d');
    const computedStyle = getComputedStyle(this);
    const fontSize = computedStyle.getPropertyValue('--skill-font-size');
    const fontWeight = computedStyle.getPropertyValue('--skill-font-weight');
    const fontFamily = computedStyle.getPropertyValue('--skill-font-family');
    const xPadding = computedStyle.getPropertyValue('--skill-x-padding');
    context.font = `${fontWeight} ${fontSize} ${fontFamily}`;
    context.letterSpacing = computedStyle.getPropertyValue('--skill-letter-spacing');
    const metrics = context.measureText(text);
    return `calc(${xPadding} * 2 + ${Math.ceil(metrics.width)}px)`;
  }
}

window.customElements.define('skill-popper', SkillPopper);
