import '@brightspace-ui/core/components/button/button.js';

import { html, LitElement, nothing } from 'lit';
import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';

class NovaFlow extends LocalizeNova(LitElement) {

  static get properties() {
    return {
      _steps: { type: Array },
      _currentStep: { type: Number },
    };
  }

  constructor() {
    super();
    this._steps = [];
    this._currentStep = 0;
  }

  get _stepElements() {
    return Array.from(this.children);
  }

  connectedCallback() {
    super.connectedCallback();

    this.addEventListener('nova-flow-step-next-enabled', this._stepReady);

    this._steps = this._stepElements.map(child => ({
      name: child.step,
      skippable: child.skippable,
      blockBack: child.blockBack,
      nextEnabled: child.nextEnabled,
    }));
    this._currentStep = 0;
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('nova-flow-step-next-enabled', this._stepReady);
  }

  get data() {
    return this._stepElements.reduce((acc, step) => {
      if (step.data && !step.shouldSkip(acc)) {
        acc[step.step] = step.data;
      }

      return acc;
    }, {});
  }

  _stepReady(e) {
    this._steps = this._steps.map(step => {
      if (step.name === e.detail.step) {
        return {
          ...step,
          nextEnabled: e.detail.nextEnabled || step.skippable,
        };
      }
      return step;
    });
  }

  _goBack() {
    this._currentStep = Math.max(0, this._currentStep - 1);

    if (this._stepElements[this._currentStep].shouldSkip(this.data)) {
      this._goBack();
    }
  }

  _goNext() {
    this._currentStep = Math.min(this._steps.length - 1, this._currentStep + 1);

    if (this._stepElements[this._currentStep].shouldSkip(this.data)) {
      this._goNext();
    }
  }

  _finish() {
    this.dispatchEvent(new CustomEvent('completed'));
  }

  render() {
    return html`
      <div class="progress-bar">
        progress bar: ${this._currentStep + 1} / ${this._steps.length}
      </div>
      <div class="slotted-content">
        ${this._steps.length ? html`
          <slot name="${this._steps[this._currentStep].name}"></slot>
        ` : nothing}
      </div>
      <div class="navigation-buttons">
          ${this._currentStep > 0 && !this._steps[this._currentStep].blockBack ? html`
            <d2l-button class="nova-flow-previous" @click=${this._goBack}>${this.localize('general.button-text.previous')}</d2l-button>
          ` : nothing}
          ${this._currentStep < this._steps.length - 1 ? html`
            <d2l-button class="nova-flow-next" primary ?disabled=${!this._steps[this._currentStep].nextEnabled} @click=${this._goNext}>${this.localize('general.button-text.next')}</d2l-button>
          ` : nothing}
          ${this._currentStep === this._steps.length - 1 ? html`
            <d2l-button class="nova-flow-finish" primary ?disabled=${!this._steps[this._currentStep].nextEnabled} @click=${this._finish}>${this.localize('general.button-text.finish')}</d2l-button>
          ` : nothing}
      </div>
    `;
  }

}

window.customElements.define('nova-flow', NovaFlow);
