export const Actions = {
  MY_LIST: 'myList',
  STREAMS: 'streams',
  STREAM_ORDER: 'streamOrder',
  ALL_STREAMS: 'allStreams',
  SKILLS_DATA: 'skillsData',
  CATALOG_PROVIDERS: 'catalogProviders',
  CAREER_FILTER_ITEMS: 'careerFilterItems',
  NO_FETCH_REQUIRED: 'noFetchRequired',
};
