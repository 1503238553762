import '@brightspace-ui/core/components/tag-list/tag-list.js';
import '@brightspace-ui/core/components/tag-list/tag-list-item.js';
import '@brightspace-ui/core/components/button/button-icon.js';

import { css, html, LitElement } from 'lit';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { LocalizeNova } from '../../../../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaFlowMixin } from '../../../../../../shared/mixins/nova-flow-mixin/nova-flow-mixin.js';

class ClientOnboardingIndustry extends RequesterMixin(NovaFlowMixin(LocalizeNova(LitElement))) {

  static get properties() {
    return {
      lightcastCompanyId: { type: String, attribute: 'lightcast-company-id' },
      _industryList: { type: Array },
      _editMode: { type: Boolean },
    };
  }

  static get styles() {
    return [
      css`
        :host {
          display: block;
        }
      `,
    ];
  }

  constructor() {
    super();
    this._industryList = [];
    this._editMode = false;
    this._foundIndustry = false;
  }

  async connectedCallback() {
    super.connectedCallback();

    this.client = this.requestInstance('d2l-nova-client');
  }

  async updated(changedProperties) {
    if (changedProperties.has('lightcastCompanyId')) {
      if (this.lightcastCompanyId) {
        const lightcastCategoriesData = await this.client.lightcastSkillCategories(this.lightcastCompanyId);
        if (lightcastCategoriesData.skillCategories.length) {
          this._industryList = lightcastCategoriesData.skillCategories.slice(0, 3);
          this._foundIndustry = true;

          this.requestUpdate();
        }
        // Use this code instead if we decide we want to display the NAIC 4 instead of the skill categories
        // const lightcastIndustryData = await this.client.lightcastSkillCategories(this.lightcastCompanyId);
        // if (lightcastIndustryData.industryName) {
        //   this._industryList = [lightcastIndustryData.industryName];
        //   this._foundIndustry = true;
        // }
      }

      this.updateData({
        industryList: this._industryList,
      });

      this.updateNextEnabled(this._foundIndustry);
    }
  }

  _startEditing() {
    this._editMode = true;
  }

  _clearIndustry(e) {
    this._industryList = this._industryList.filter(industry => industry !== e.target.text);

    this.updateData({
      industryList: this._industryList,
    });

    this.updateNextEnabled(this._industryList.length > 0);
  }

  _addIndustry() {
    const inputElement = this.shadowRoot.getElementById('new-industry');
    const newIndustry = inputElement.value;
    if (newIndustry) {
      this._industryList = [...this._industryList, newIndustry];
      inputElement.value = '';

      this.updateData({
        industryList: this._industryList,
      });

      this.updateNextEnabled(true);
    }
  }

  render() {
    return html`
      <div class="client-onboarding-industry-description">
        ${this._foundIndustry ? html`
          We've matched your company details to an industry.
        ` : html`
          We couldn't find a match for your company's industry. Please add it below.
        `}
        
      </div>
      <div class="client-onboarding-industry-list">
        <d2l-tag-list description="Industry list">
          ${this._industryList.map(industry => html`
            <d2l-tag-list-item 
              text=${industry}
              ?clearable=${this._editMode}
              @d2l-tag-list-item-clear=${this._clearIndustry}>
            </d2l-tag-list-item>`)}
        </d2l-tag-list>
        <div class="client-onboarding-industry-list-add" ?hidden=${!this._editMode}>
          <d2l-input-text id="new-industry" label="Add Industry"></d2l-input-text>
          <d2l-button-icon text="Add" icon="tier1:add" @click=${this._addIndustry}></d2l-button-icon>
        </div>
      </div>
      <div class="client-onboarding-industry-edit" ?hidden=${this._editMode}>
        <d2l-button-icon text="Edit" icon="tier1:edit" @click=${this._startEditing}></d2l-button-icon>
      </div>
    `;
  }

}

window.customElements.define('client-onboarding-industry', ClientOnboardingIndustry);
