export const NovaFlowMixin = superclass => class extends superclass {

  static get properties() {
    return {
    };
  }

  get _stepElement() {
    return this.parentElement;
  }

  updateNextEnabled(nextEnabled) {
    this._stepElement.nextEnabled = nextEnabled;
  }

  updateData(data) {
    this._stepElement.data = data;
  }

};
