import * as rive from '@rive-app/canvas';
import { html, LitElement } from 'lit';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { LocalizeNova } from '../../mixins/localize-nova/localize-nova.js';

class NovaAnimation extends LocalizeNova(SkeletonMixin(LitElement)) {
  static get properties() {
    return {
      height: { type: String },
      src: { type: String },
      stateMachine: { type: String },
      width: { type: String },
    };
  }

  firstUpdated() {
    const options = {
      root: this.parentElement,
    };
    const intersectionObserver = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];

      if (entry.intersectionRatio > 0) {
        this._loadRive();
        observer.disconnect();
      }

    }, options);

    intersectionObserver.observe(this);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    if (this._rive) {
      this._rive.cleanup();
    }

    window.removeEventListener('resize', this._resizeAnimation);
  }

  _loadRive() {
    const DEFAULT_STATE_MACHINE = 'State Machine 1';
    this._rive = new rive.Rive({
      src: this.src,
      canvas: this.shadowRoot.querySelector('#canvas'),
      autoplay: true,
      onLoad: this._resizeAnimation,
      stateMachines: this.stateMachine ?? DEFAULT_STATE_MACHINE,
    });

    window.addEventListener('resize', this._resizeAnimation);
  }

  _resizeAnimation() {
    if (this._rive) {
      this._rive.resizeDrawingSurfaceToCanvas();
    }
  }

  render() {
    const height = this.height ?? '100';
    const width = this.width ?? '100';
    return html`
      <canvas id="canvas" style="width: ${width}px; height: ${height}px;" width=${width} height=${height}></canvas>
    `;
  }
}

window.customElements.define('nova-animation', NovaAnimation);
