import { html, LitElement } from 'lit';

import '@brightspace-ui/core/components/dropdown/dropdown-menu.js';
import '@brightspace-ui/core/components/menu/menu.js';

import './nova-menu-dropdown-button.js';

class NovaMenuDropdown extends ((LitElement)) {
  static get properties() {
    return {
      /**
     * Description for the menu button for accessibility
     * @type {string}
     */
      description: { type: String },

      disabled: { type: Boolean },

      /**
       * Label for the menu button
       * @type {string}
       */
      text: { type: String },

      /**
       * Icon for the menu button
       * @type {string}
       */
      icon: { type: String },

      /**
       * Icon for the menu button
       * @type {string}
       */
      label: { type: String },

      /**
       * Icon for the menu button
       * @type {string}
       */
      svg: { type: String },

      /**
       * Hide chevron icon
       * @type {boolean}
       */
      hideChevron: { type: Boolean, attribute: 'hide-chevron' },
    };
  }

  constructor() {
    super();
    this.hideChevron = false;
  }

  render() {
    return html`
      <nova-menu-dropdown-button description=${this.description} ?disabled=${this.disabled} icon=${this.icon} svg=${this.svg} text=${this.text} ?hide-chevron="${this.hideChevron}">
        <d2l-dropdown-menu>
          <d2l-menu label=${this.label} @d2l-menu-item-change=${this._updateText}>
           <slot></slot>
          </d2l-menu>
        </d2l-dropdown-menu>
      </nova-menu-dropdown-button>
    `;
  }
}

customElements.define('nova-menu-dropdown', NovaMenuDropdown);
