import { css, html, LitElement, nothing } from 'lit';

import '@brightspace-ui/core/components/button/button-subtle.js';
import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/icons/icon-custom.js';
import { VisibleOnAncestorMixin, visibleOnAncestorStyles } from '@brightspace-ui/core/mixins/visible-on-ancestor/visible-on-ancestor-mixin.js';
import { DropdownOpenerMixin } from '@brightspace-ui/core/components/dropdown/dropdown-opener-mixin.js';
import { dropdownOpenerStyles } from '@brightspace-ui/core/components/dropdown/dropdown-opener-styles.js';
import novaSvgIcons from './nova-svg-icons.js';

class NovaMenuDropdownButton extends DropdownOpenerMixin(VisibleOnAncestorMixin(LitElement)) {
  static get properties() {
    return {
      /**
       * Description for the dropdown button for accessibility
       * @type {string}
       */
      description: { type: String },

      disabled: { type: Boolean },

      /**
       * Icon for the dropdown button
       * @type {string}
       */
      icon: { type: String },

      /**
       * svg for the dropdown button
       * @type {string}
       */
      svg: { type: String },

      /**
       * Label for the dropdown button
       * @type {string}
       */
      text: { type: String },

      /**
       * Hide chevron icon
       * @type {boolean}
       */
      hideChevron: { type: Boolean, attribute: 'hide-chevron' },
    };
  }

  static get styles() {
    return [dropdownOpenerStyles, visibleOnAncestorStyles, css`
      :host {
        display: inline-block;
      }

      .dropdown-label {
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: top;
        width: 80px;
      }
`];
  }

  constructor() {
    super();
    this.hideChevron = false;
  }

  render() {
    return html`
      <d2l-button-subtle aria-label=${this.description} description=${this.description} ?disabled=${this.disabled} >
        ${this.icon ? html`<d2l-icon icon=${this.icon}></d2l-icon>` : nothing}
        ${this.svg ? html`<d2l-icon-custom size="tier1">${novaSvgIcons[this.svg]}</d2l-icon-custom>` : nothing}
        ${this.text ? html`<div class="dropdown-label">${this.text}</div>` : nothing}
        ${!this.hideChevron ? html`<d2l-icon icon="tier1:chevron-down-small"></d2l-icon>` : nothing}
      </d2l-button-subtle>
      <slot></slot>
    `;
  }

  /**
   * Gets the "d2l-button-subtle" opener element (required by dropdown-opener-mixin).
   * @return {HTMLElement}
   */
  getOpenerElement() {
    return this.shadowRoot && this.shadowRoot.querySelector('d2l-button-subtle');
  }
}

customElements.define('nova-menu-dropdown-button', NovaMenuDropdownButton);
